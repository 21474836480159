import React, { useState, useContext, useEffect } from "react";
import { FormControl } from "react-bootstrap";
import Select from "react-select";
import {
  RESULT_SENT_OPTIONS,
  GENDER_OPTIONS,
  FILTER_SHOW_STATUS,
  FILTER_TEST_STATUS,
  TEST_TYPE_OPTIONS,
  ORDER_CANCELLED_RESASON_OPT,
  INSURANCE_PROVIDER,
  EMPLOYEE_STATUS,
  NETWORK_STATUS,
  VACCINATED_OPTIONS,
  CAPACITY_OPTIONS,
  TRANSITION_STATUS_OPT,
  stringConstant,
  BATCH_OPTIONS,
  CUSTODY_OPT,
  ORDER_STATUS_OPT,
  ELIGIBILITY_STATUS,
  CONFIG,
  RESULT_SETTINGS_OPT,
  archstone_STATUS_OPT,
  RELEASE_STATUS_OPTION,
  BATCH_OPTIONS_COMPLETED_TEST,
  RESULT_EMAILED_OPTION,
  ORDER_STATUS_DROPDOWN,
} from "constant";
import {
  ReactSelectCustomContainer,
  Option,
  customStyles,
} from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import { filterMapping } from "filterMappingConstant";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { AppContext } from "context/app-context";
import SingleDatePicker from "Date/SingleDatePicker";

import api from "api";
import { useMemo } from "react";

import { providerOptionList, selectedProvider } from "store/features/providers/providersSelectors";
import { useSelector } from "react-redux";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import "react-tabs/style/react-tabs.css";
import renderFilters from "./RenderFilter";
import { createFilterMapping, createFilterMappingWithoutGroup } from "utils";
import { refLabsSelectors } from "store/features/locations/locationsSelectors";
import { customUniqBy } from "../util/customLodash";
import { useOptions } from "views/TestTypeSetting/TestTypeComponent/TestTypeSelect";
import { TEST_SETTING_TYPES } from "constant";

const Filter = ({
  filterTerms,
  setFilter,
  filter,
  isUsersTable,
  triggerFilter,
  setTriggerFilter,
  handleClearFilter,
  personalisationData,
  isGrouped = false,
  setFilterTabIndex,
  filterTabIndex,
  filterTab = null,
}) => {
  const [clearFilter, setClearFilter] = useState(false);

  const [testTypes, setTestTypes] = useState([]);

  const [users, setUsers] = useState([]);

  const appContext = useContext(AppContext);

  // const providers = useSelector(providerOptionList);
  const { providers, filteredProviders } = useSelector(selectedProvider);

  const { options: DEPARTMENT_OPTIONS } = useOptions(TEST_SETTING_TYPES.DEPARTMENT);
  const { options: SOURCE_OPTIONS } = useOptions(TEST_SETTING_TYPES.SOURCE);

  const labLocations = useSelector(refLabsSelectors);

  const providerOptionsForResult = useMemo(() => {
    return providers.map((l) => {
      return { ...l, value: l.name, label: l.name };
    });
  }, [providers]);

  const siteOptions = () => {
    if (stringConstant.show === "Client") {
      return appContext.sites.map((m) => ({ value: m.id, label: m.name }));
    }
    return appContext.sites.map((m) => ({ value: m.id, label: m.name }));
  };

  const getLabOptions = useMemo(() => {
    return labLocations.map((l) => {
      return { ...l, value: l.name, label: l.name };
    });
  }, [labLocations]);

  const getList = async () => {
    const testAssayTypes = await api.getTestTypesForFilter();

    setTestTypes(testAssayTypes);
  };

  const getUserFromLocalDB = async () => {
    let getUserDAta = await api.getUserFromLocalDB(CONFIG.isWhiteLabel ? CONFIG.whiteLabelID : "");
    setUsers(getUserDAta);
  };

  useEffect(() => {
    getList();
    getUserFromLocalDB();
  }, []);

  const usersData = useMemo(() => {
    if (!users) return;
    return users
      .filter((u) => u.roles?.includes("Tester") || u.roles?.includes("Admin") || u.roles?.includes("Lab"))
      .map((u) => {
        return {
          value: u.userID,
          label: `${u.firstName} ${u.lastName}`,
          phone_number: u.phone_number?.replace(/ /g, ""),
        };
      });
  }, [users]);

  const filterAlias = (term) => {
    if (personalisationData) {
      const personaliseKey = personalisationData.find((f) => f.title === term);
      if (personaliseKey?.filterKey || personaliseKey?.itemKey) {
        return personaliseKey?.filterKey || personaliseKey?.itemKey;
      }
    }

    switch (term) {
      case "Crew ID":
        return "id";
      case "Name":
        return "name";
      case "First Name":
        return "firstName";
      case "Last Name":
        return "lastName";
      case "FirstName":
        return "first";
      case "LastName":
        return "last";
      case "Phone":
        if (isUsersTable) {
          return "phone_number";
        } else {
          return "phoneNumber";
        }
      // return "phoneNumber";
      case "Email":
        return "email";
      case "Accession No":
      case "Barcode":
        return "barcode";
      case "Test Status":
      case "Status":
        return "status";
      case "Referring Physician":
        return "providerID";
      case "Result":
        return "result";
      // case "Lab":
      //   return "lab";
      case "Role":
        return "userRole";
      case "Contact Name":
      case "Primary Contact":
        return "contact_name";
      case "Contact Phone":
        return "contact_phone";
      case "Contact Email":
        return "contact_email";
      case "Test Type":
        return "test_type";
      case "Associated":
        return "associated";
      case "Tester":
        return "tester_name";
      case "Crew Id":
        return "employeeID";
      case "Quarantined":
        return "isQuarantined";
      case `${stringConstant.sequenceNo}`:
        return "sequenceNo";
      case "ReferenceID":
        return "referenceID";
      case "Result Sent":
        return "emailSend";
      case "Reason":
        return "reason";
      case "Insurance Info":
        return "insurance_required";
      case "Insurance Card":
        return "sendInsuranceCard";
      case "Vaccination Card":
        return "vaccinationCard";
      case "Vaccination Admit":
        return "admit";
      case "External Test":
        return "externalTest";
      case "External Test Admit":
        return "externalTestAdmit";
      case "Pattern Testing":
        return "patternTesting";
      case "Pre-Registration":
        return "preRegistration";
      case "HR Registration":
        return "isHRPR";
      case "Automate Rapid Test":
        return "antigen";
      case "Print Labels":
        return "tubes_provided";
      case "Antigen":
        return "default_antigen";
      case "Molecular":
        return "default_molecular";
      case "Accula":
        return "default_other";
      case "Send Insurance":
        return "sendInsurance";
      case "BarCodes":
        return "barCodeProvided";
      case "Result Type":
        return "resultType";
      case "White Label":
        return "whiteLabel";
      case "Gender":
        return "sex";
      case "ID Number":
        return "id_number";
      case "City":
        return "city";
      case "Vaccinated":
        return "isVaccinated";
      case "False Positive":
        return "isFalsePositive";
      case "Reference ID":
        return "referenceID";
      case "Shipment":
        return "enableShipment";
      case "Street":
        return "street";
      case "Send Quantity":
        return "testSendQty";
      case "Vaccination Type":
        return "vaccinationType";
      case "Status Step":
        return "step";
      case "Date of Birth":
        return "dob";
      case "Order Status":
        return "orderStatus";
      case "Eligibility Status":
        return "eligibilityStatus";
      case "Capcity":
        return "capcity";
      case "Cancelled Reason":
        return "cancelledReason";
      case "Show Status":
      case "Ord Status":
        return "status";
      case "isVaccinated":
        return "vaccinated";
      case "White Glove":
        return "whiteGlove";
      case "Pattern":
        return "pattern";
      case "Capacity":
        return "capacity";
      case "Transition Status":
        return "transitionStatus";
      case "Date Created":
        return "startDate";
      case "Date End":
        return "endDate";
      case "Submitted to H&S":
        return "batch";
      case `${stringConstant.show}`:
        return "siteID";
      case "Custody Status":
        return "pcrStatus";
      case "Payer":
      case "Primary Payer":
        return "payerId";
      case "Additional Payer":
        return "secondaryInsurance";
      case "Additional Payer Number" || "Additional Member ID":
        return "secondaryInsuranceNumber";
      case "Primary Payer Number" || "Member ID":
        return "insurance_number";
      case "Eligibility":
        return "eligibilityStatus";
      case "Ins Name":
        return "Name";
      case "Ins Alias":
        return "shortName";
      case "Electronic Claims":
        return "eClaim";
      case "Electronic Eligibility":
        return "eEligibility";
      case "Electronic ERA":
        return "ERA";
      case "NPI":
      case "Insurance Company":
        return "npi";
      case "Note":
        return "note";
      case "Last Updated":
        return "updatedAt";
      case "Panel Name":
        return "name";
      case "Arkstone Status":
        return "arkstoneStatus";
      case "Release Status":
        return "releaseStatus";
      default:
        return term;
    }
  };

  const lowerCaseKeys = (obj) => {
    const keys = Object.keys(obj);
    const newObj = {};
    for (const key of keys) {
      newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
  };

  const dateRangeMultiSelection = (filterTerm) => (
    <DateRangePicker
      onApply={(event, picker) => {
        picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: { startDate: picker.startDate, endDate: picker.endDate },
        });
      }}
      onCancel={(event, picker) => {
        picker.element.val("");
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: {},
        });
      }}
      initialSettings={
        filter[filterAlias(filterTerm)]
          ? filter[filterAlias(filterTerm)]
          : {
              drops: "auto",
              autoUpdateInput: false,
              locale: {
                cancelLabel: "Clear",
              },
            }
      }
    >
      <input type="text" placeholder="Select Date Range" readOnly className="dateRangeFilter" defaultValue="" />
    </DateRangePicker>
  );

  const dateRangeSelection = (filterTerm) => (
    <SingleDatePicker
      onApply={(event, picker) => {
        const date = moment(picker.startDate);
        if (date.isValid()) {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: date.format("YYYY-MM-DD"),
          });
        }
      }}
      onCancel={(event, picker) => {
        picker.element.val("");
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: {},
        });
      }}
      value={filter[filterAlias(filterTerm)]}
      currentDate={true}
    />
  );

  const getDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];

    const prevSelectedVal =
      prevData !== undefined
        ? data.filter((d) => {
            return d.value === prevData || String(d.value).indexOf(String(prevData)) !== -1;
          })
        : null;

    return (
      <Select
        styles={customStyles}
        options={data}
        blurInputOnSelect={true}
        className="result-filter-width filterSelector"
        defaultValue={null}
        menuPlacement="auto"
        value={
          prevSelectedVal && Array.isArray(prevSelectedVal) && prevSelectedVal?.length > 0 ? prevSelectedVal[0] : null
        }
        isClearable
        onChange={(selectedOption) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: selectedOption ? selectedOption.value : null,
          });
        }}

        // onChange={(selectedOption) => {
        //   if (selectedOption) {
        //     setFilter({
        //       ...filter,
        //       [filterAlias(filterTerm)]: selectedOption.value,
        //     });
        //   } else {
        //     setFilter({
        //       ...filter,
        //       [filterAlias(filterTerm)]: "",
        //     });
        //   }
        // }}
      />
    );
  };

  const getCheckBox = (filterTerm) => (
    <div className="filterSwitchButton">
      <label className="m-0">{filterTerm}:</label>
      <SwitchToggle
        checked={filter[filterAlias(filterTerm)]}
        handleChange={(e) =>
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.target.checked,
          })
        }
      />
    </div>
    // <CheckBoxButton
    //   id={filterTerm}
    //   value={filter[filterAlias(filterTerm)]}
    //   name={filterTerm}
    //   checked={filter[filterAlias(filterTerm)]}
    //   label={filterTerm}
    //   handleChange={(e) =>
    //     setFilter({
    //       ...filter,
    //       [filterAlias(filterTerm)]: e.target.checked,
    //     })
    //   }
    // />
  );

  // const getMultiDropDown = (data, filterTerm) => {
  //   const prevData = filter[filterAlias(filterTerm)];
  //   const prevSelectedVal = prevData ? data.filter((d) => prevData.indexOf(d.value) !== -1) : null;
  //   return (
  //     <Select
  //       options={data}
  //       blurInputOnSelect={true}
  //       className="result-filter-width filterSelector"
  //       defaultValue={null}
  //       menuPlacement="auto"
  //       isMulti
  //       value={prevSelectedVal}
  //       onChange={(e) => {
  //         setFilter({
  //           ...filter,
  //           [filterAlias(filterTerm)]: e.map((t) => t.value),
  //         });
  //       }}
  //     />
  //   );
  // };

  const getMultiDropDown = (data, filterTerm, keyToReturn = "value") => {
    const prevData = filter[filterAlias(filterTerm)];

    const prevSelectedVal = prevData
      ? customUniqBy(
          data.filter((d) => prevData.includes(d.value) || prevData.includes(d.label)),
          "label"
        )
      : null;

    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="result-filter-width filterSelector"
        styles={customStyles}
        defaultValue={null}
        menuPlacement="auto"
        isMulti
        hideSelectedOptions={false}
        components={{ Option, ValueContainer: ReactSelectCustomContainer }}
        value={prevSelectedVal}
        onChange={(e) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.map((t) => t[keyToReturn]),
          });
        }}
      />
    );
  };

  const filterContent = (filterTerm) => {
    switch (filterTerm) {
      case "Result Sent":
        return getDropDown(RESULT_SENT_OPTIONS, filterTerm);
      case "Custody Status":
      case "Cust Status":
        return getDropDown(CUSTODY_OPT, filterTerm);
      case "Submitted to H&S":
        return getDropDown(BATCH_OPTIONS, filterTerm);
      case "Gender":
        return getDropDown(GENDER_OPTIONS, filterTerm);
      case "Date Of Birth":
      case "DOB":
      case "Order Date":
      case "Date Received":
      case "Date Sent":
      case "Collected":
      case "Date of Order":
      case "Last Order":
        return dateRangeSelection(filterTerm);
      case "Collected Date":
      case "Processed":
      case "Test Date":
      case "Last Updated":
      case "Effective Date":
      case "Retired Date":
      case "Last Used":
      case "Last Claim":
        return dateRangeMultiSelection(filterTerm);
      case "Test Type":
      case "Test Name":
      case "Test":
        return getMultiDropDown(testTypes, filterTerm);
      case "Tester":
      case "Received By":
      case "Released By":
      case "Resulted By":
        return getMultiDropDown(usersData, filterTerm, filterTab === "pendingResult" ? "label" : "value");
      case "Collected By":
        return getMultiDropDown(usersData, filterTerm, "label");
      case "Show Status":
        return getDropDown(FILTER_SHOW_STATUS, filterTerm);
      case "Test Status":
        return getDropDown(FILTER_TEST_STATUS, filterTerm);
      case "Result Type":
        return getDropDown(TEST_TYPE_OPTIONS, filterTerm);
      case "Restated":
      case "Pattern":
      case "White Glove":
      case "isVaccinated":
        return getDropDown(VACCINATED_OPTIONS, filterTerm);
      case "Capacity":
        return getDropDown(CAPACITY_OPTIONS, filterTerm);
      case "Department":
        return getDropDown(DEPARTMENT_OPTIONS, filterTerm);
      case "Source":
        return getDropDown(SOURCE_OPTIONS, filterTerm);
      case "Transition Status":
        return getDropDown(TRANSITION_STATUS_OPT, filterTerm);
      case "Date Created":
        return dateRangeMultiSelection(filterTerm);
      case "Cancelled Reason":
        return getDropDown(ORDER_CANCELLED_RESASON_OPT, filterTerm);
      case "Ord Status":
        return getDropDown(ORDER_STATUS_OPT, filterTerm);
      case "Status":
        return getDropDown(EMPLOYEE_STATUS, filterTerm);
      case "Referring Physician":
        return getDropDown(providers, filterTerm);
      case "Refererring Physician":
        return getDropDown(providerOptionsForResult, filterTerm);
      case "Date End":
        return dateRangeMultiSelection(filterTerm);
      case "Eligibility":
      case "Eligibility Status":
        return getDropDown(ELIGIBILITY_STATUS, filterTerm);
      case `${stringConstant.show}`:
      case "External Client":
        return getDropDown(siteOptions(), filterTerm);
      case "Reference Lab":
        return getDropDown(getLabOptions, filterTerm);
      case "Status":
        return getDropDown(EMPLOYEE_STATUS, filterTerm);
      case "Active":
        return getDropDown(EMPLOYEE_STATUS, filterTerm);
      case "Network":
        return getDropDown(NETWORK_STATUS, filterTerm);
      case "Arkstone Status":
      case "Arkstone":
        return getDropDown(archstone_STATUS_OPT, filterTerm);
      case "Release Status":
        return getDropDown(RELEASE_STATUS_OPTION, filterTerm);
      case "H&S":
        return getDropDown(BATCH_OPTIONS_COMPLETED_TEST, filterTerm);
      case "Electronic Claims":
      case "Electronic Eligibility":
      case "Electronic ERA":
        return getCheckBox(filterTerm);
      case "Payer":
      case "Additional Payer":
      case "Primary Payer":
        return getMultiDropDown(
          INSURANCE_PROVIDER.map((ins) => {
            return { value: ins.payerid, label: ins.label };
          }),
          filterTerm
        );
      case "Result Emailed":
        return getDropDown(RESULT_EMAILED_OPTION, filterTerm);
      case "Order Status":
        return getDropDown(ORDER_STATUS_DROPDOWN, filterTerm);
      case "Result":
        return getDropDown(RESULT_SETTINGS_OPT, filterTerm);
      // case "Result":
      //   return getMultiDropDown(TEST_RESULT_OPTIONS, filterTerm);
      default:
        return (
          <FormControl
            onChange={(e) => {
              setFilter({
                ...filter,
                [filterAlias(filterTerm)]: e.target.value.toLowerCase(),
              });
            }}
            placeholder={`Enter ${filterTerm}`}
            value={filter[filterAlias(filterTerm)]}
          />

          // <FormControl
          //   onChange={(e) => {
          //     setFilter({
          //       ...filter,
          //       [filterAlias(filterTerm)]: e.target.value.toLowerCase(),
          //     });
          //   }}
          //   value={filter[filterAlias(filterTerm)]}
          // />
        );
    }
  };

  const filterCategories = useMemo(() => {
    if (personalisationData) {
      return createFilterMapping(personalisationData, filterTab);
    }
    const categories = {};

    filterTerms.forEach((term) => {
      const category = filterMapping[term] || "Others";
      if (!categories[category]) {
        categories[category] = [];
      }
      categories[category].push(term);
    });

    return categories;
  }, [filterTerms]);

  return (
    <div>
      <div className="filterResetBtn">
        <span
          onClick={() => {
            Array.from(document.querySelectorAll("input")).forEach((input) => (input.value = ""));
            setFilter({});
            if (handleClearFilter) handleClearFilter();
          }}
          className="linkedText textUnderLine"
        >
          Clear Filter
        </span>
      </div>
      <div style={{ background: "white" }}>
        {renderFilters({
          categories: filterCategories,
          triggerFilter,
          setFilter,
          filterContent,
          isGrouped,
          filterTerms:
            !isGrouped && personalisationData ? createFilterMappingWithoutGroup(personalisationData) : filterTerms,
          setFilterTabIndex,
          filterTabIndex,
        })}
      </div>
    </div>
  );
};

export default Filter;

import payerList from "Jsons/payer-list.json";
import placeOfServices from "Jsons/POS.json";
import { getPersonalizationIds } from "utils";
export const PAGE_LIMIT = 20;
import moment from "moment";
export const WHITE_LABEL = "whitelabel";
export const NOTES_MAX_CHARACTER_LENGTH = 1500;
export const BUCKET_EXTERNAL_IMAGES = "employee-external-tests";

export const INSURANCE_PROVIDER = payerList;
export const POS_OPT = placeOfServices;

import {
  default as co_lab_image,
  default as image,
  default as lis_lable_image,
} from "assets/img/MedFlow-Alpha-LIS.png";
import Status from "components/Status/Status";

export const TABLE_COLUMN_TYPES = {
  shortDate: "shortDate",
  dateTime: "dateTime",
  text: "text",
  upperCaseText: "upperCaseText",
  firstLetterCapital: "firstLetterCapital",
  number: "number",
  email: "email",
  phone: "phone",
  currency: "currency",
  image: "image",
  insuranceProvider: "insuranceProvider",
  result: "result",
  fixedValue: "fixedValue",
  json: "json",
};

const img = () => {
  if (process.env.REACT_APP_LOGO.includes("co_lab")) {
    return co_lab_image;
  } else if (process.env.REACT_APP_ENV_WHITE === "whitelabel") {
    return lis_lable_image;
  }
  return image;
};
export const logoImage = img();
export const COMBINE_PROFILE = "Combine Profile";
export const LOCATIONS_OPTIONS = [
  { value: "At Home", label: "At Home" },
  { value: "On Location", label: "On Set" },
];

export const CONFIG = {
  logoImage: img(),
  basename: "LIS",
  url: process.env.REACT_APP_URL,
  baseurl: process.env.REACT_APP_BASE_URL,
  isWhiteLabel: process.env.REACT_APP_ENV_WHITE === WHITE_LABEL,
  env: process.env.REACT_APP_ENV,
  isLive: process.env.REACT_APP_ENV === "live",
  whiteLabelID: process.env.REACT_APP_WHITELABEL_ID,
  clientID: process.env.REACT_APP_CLIENT,
  siteID: "",
  allowSites: [],
  appPackage: process.env.REACT_APP_PACKAGE,
  envType: process.env.REACT_APP_ENV === "live" ? "live" : "test",
  eligibilityBucket: process.env.REACT_APP_ELIGIBILITY_BUCKET,
  isLabType: process.env.REACT_APP_COMPANY_TYPE === "LAB",
};

export const LAB_SFTP_BUCKET = {
  BIO_LAB: "biolabs-tests",
};

// Chart Stats Labels
export const SUMMARY_STATS_TYPE = {
  labstats: "Lab Stats",
  sitestats: "Site Stats",
  clientstats: "Client Stats",
  clientsitestats: "Client Stats",
};

//Charts Tables Key Labels
export const SUMMARY_TABLES_KEY_LABELS = {
  labstats: "labID",
  sitestats: "siteID",
  clientstats: "clientID",
  clientsitestats: "clientID",
};
export const TEST_TYPE_OPT = [{ value: "PCR", label: "PCR" }];

export const TEST_ORDER_LIMIT_CATEGORY_VALUE = {
  0: "Per Week",
  1: "Per Month",
  2: "Per Year",
};
export const newLabClientObject = {
  name: "",
  practiceId: "",
  address: "",
  phone_number: "",
  fax: "",
  email: "",
  contact_name: "",
  contactName: "",
  contactFax: "",
  contact_email: "",
  isActive: true,
  isArchive: false,
  isTesting: true,
  discount: "",
  commission: "",
  isNew: true,
  setting: {
    hippaRequired: true,
  },
};
export const MIN_TEST_LIMIT = 8;
export const ATTACHMENT_FILES_LIMIT = 5;
export const TEST_ORDER_LIMIT_CATEGORY = [
  { label: "Per Week", value: "0" },
  { label: "Per Month", value: "1" },
  { label: "Per Year", value: "2" },
];

//Charts Tables Stats Labels
export const SUMMARY_TABLES_STATS_TYPE = {
  labstats: "Lab",
  sitestats: "Site",
  clientstats: "Client",
  clientsitestats: "Client",
};

export const VACCINATED_OPTIONS = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];

export const BATCH_OPTIONS = [
  {
    value: "yes",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" tickIcon />
      </div>
    ),
  },
  {
    value: "no",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];

export const CAPACITY_OPTIONS = [
  { value: "Staff", label: "Staff" },
  { value: "Guest", label: "Guest" },
];

// IMAGES TYPE
export const IMAGE_UPLOAD_TYPE = {
  VacinationCard: "vaccination_card",
  ExternalTest: "external_test",
  ProfileImage: "profile_image",
};

export const PROFILE_IMAGE_PATH = "https://medflow-images.s3.eu-west-1.amazonaws.com/public/profile/";

// SITE STATUS
export const SITE_STATUS_OPTIONS = [
  // { value: 0, label: "Pending" },
  { value: 1, label: "Approved" },
  { value: 2, label: "Hold" },
];

export const TEST_TYPE_VALUE = {
  Lucira: "Lucira",
  PCR: "PCR",
  Antigen: "Antigen",
  Other: "Rapid PCR",
  Molecular: "Cue",
  RapidFlu: "Rapid Flu",
  Rapid_PCR: "Other",
  Antigen: "Antigen",
  Rapid_Flu: "RapidFlu",
};

export const MESSAGE_TYPE = {
  default: "default",
  info: "info",
  success: "success",
  warning: "warning",
  danger: "danger",
  error: "error",
  input: "input",
  custom: "custom",
  controlled: "controlled",
};

export const TEST_HEADER_TYPE = {
  unprocessed: "unprocessed",
  completed: "completed",
  alltests: "alltests",
  pending: "pending",
  expired: "expired",
};

export const TEST_STATUS = {
  New: "New",
  Sent: "Sent",
  Processing: "Processing",
  Pending: "Pending",
  Processed: "Processed",
};
export const LAB_CLIENT_CSV_COLUMNS = [
  "client_payer_id",
  "client_name",
  "client_address",
  "client_city",
  "client_state",
  "client_zip",
  "client_phone",
];
export const newRoleOptions = [
  { value: "Admin", label: "Admin" },
  { value: "Lab", label: "Lab" },
  { value: "Tester", label: "Tester", isWhiteLabelLab: "No" },
  { value: "Employee", label: "Crew" },
  { value: "Health & Safety", label: "Health & Safety" },
  { value: "Client", label: "Client" },
];

export const LAB_CLIENT_DROPDOWN_OPTIONS = [
  // { value: "delete", title: "Archive" },
  { value: "logs", title: "Audit Trail" },
];
export const PROVIDER_DROPDOWN_OPTIONS = [{ value: "logs", title: "Audit Trail" }];

export const PAYER_DROPDOWN_OPTIONS = [{ value: "logs", title: "Audit Trail" }];

// add new contant
export const CLI_DEFAULT_OPTIONS = {
  yes: "Yes",
  no: "No",
};
export const INSURANCE_EMPTY_OBJECT = {
  clientID: "",
  payerId: "",
  Name: "",
  address: "",
  city: "",
  state: "",
  phone: "",
  fax: "",
  eClaim: true,
  eFax: "",
  manualTerm: "",
  manualTerm: "",
  manualAmount: 0,
  manualMail: "",
  eEligibility: true,
  EDI: true,
  ERA: true,
  eraMail: false,
  claimAddress: "",
  claimCity: "",
  claimState: "",
  claimPhone: "",
  claimFax: "",
  isNew: true,
  isActive: true,
};
export const NEW_CLI_ITEM = {
  cliCode: "",
  isDefault: CLI_DEFAULT_OPTIONS.no,
};
export const CLI_ACTIONS = {
  add: "add",
  delete: "delete",
  update: "update",
};
export const MESSAGE_MODES = {
  info: "info",
  error: "error",
  success: "success",
};
export const ADMIN_USERS = ["+17777777777", "+18888888888"];
export const CLAIMS_KEYS = [
  "clientID",
  "locationID",
  "subAgentID",
  "employeeID",
  "clientName",
  "locationName",
  "subAgentName",
  "orderId",
  "orderDate",
  "localOrderId",
  "fileid",
  "paymentMethod",
  "paymentCheckNo",
  "checkDate",
  "claimNo",
  "allowedPayment",
  "paidAmount",
  "adjustedAmount",
  "overPaidAmount",
  "submissionDate",
  "reSubmissionDate",
  "insuranceProcessDate",
  "isPost",
  "plan_type",
  "total_paid",
  "crossover_carrier",
  "crossover_id",
  "claim_received_date",
  "status_code",
  "filing_code",
  "requestedBy",
  "requestedByName",
  "updatedBy",
  "updatedByName",
  "createdAt",
  "proc_array",
  "provider",
  "era",
  "eraIds",
  "isNewLogs",
  "acknowledgeBy",
  "paid_insurance",
  "assign_details",
  "toData",
  "updatedAt",
  "_version",
  "_deleted",
  "_lastChangedAt",
  "partialAmount",
];
export const PAID_CLAIM_SPANS_MODAL_FORMAT = {
  weekly: {
    datePickerFormat: "MM/dd/y",
    datePickerView: "month",
    momentFormat: "YYYY-MM-DD",
  },
  monthly: {
    datePickerFormat: "yyyy-MM",
    datePickerView: "year",
    momentFormat: "YYYY-MM",
  },
  yearly: {
    datePickerFormat: "yyyy",
    datePickerView: "decade",
    momentFormat: "YYYY",
  },
};

export const PAID_CLAIM_FILTERING_SPANS = {
  weekly: "weekly",
  monthly: "monthly",
  yearly: "yearly",
};

export const CLAIMS_STATS_DETAILS = {
  selectedSpan: PAID_CLAIM_FILTERING_SPANS.yearly,
  date: moment().format(PAID_CLAIM_SPANS_MODAL_FORMAT.yearly.momentFormat),
  payerId: "",
  rows: [],
  total: 0,
};
export const PAID_CLAIM_FILTERING_SPANS_OPTS = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

export const CLAIM_EDIT_STATUS = {
  draft: "draft",
  submitted: "submitted",
  rejected: "rejected",
  approved: "approved",
  paid: "paid",
  denied: "denied",
  cancelled: "cancelled",
  partial: "partial",
  rejectedanddenied: "rejectedanddenied",
  unpaid: "unpaid",
  sent: "submitted",
};
export const DEFAULT_USRERS_PERMISSIONS = {
  dashboard: { access: true },
  employee: { access: true, write: true, delete: true },
  checkEligibility: { access: true, write: true, delete: true },
  claims: { access: true, write: true, delete: true },
  users: { access: true, write: true, delete: true },
  settings: { access: true },
  claiminvoice: { access: true },
};
export const EMP_CLAIM_BLANK_OPTS = {
  "First Name": "pat_name_f",
  "Last Name": "pat_name_l",
  Email: "pat_email",
  "Phone Number": "pat_phone",
  Address: "pat_addr_1",
  "Apt/Unit#": "pat_addr_2",
  City: "pat_city",
  State: "pat_state",
  "Zip Code": "pat_zip",
  "Date Of Birth": "pat_dob",
  Gender: "pat_sex",
  Insurance: "payer_name",
  "Insurance #": "ins_number",
  "Claim ID": "claimNo",
  "Claim Amount": "total_charge",
  "Adjusted Amount": "adjustedAmount",
  "Paid Amount": "paidAmount",
  DOS: "from_date_1",
  Status: "status",
  "LTCS ID": "pcn",
  "B-ID": "remote_claimid",
  "Processed By": "paid_insurance",
};
export const LAB_ROTUES = {
  "/dashboard": "dashboard",
  "/members": "employee",
  "/checkeligibility": "checkEligibility",
  "/employeeclaim": "claims",
  "/users": "users",
  "/settings": "settings",
  "/claiminvoice": "claiminvoice",
};
export const ORDER_BY_DIRECTION = {
  ASCEDNING: "asc",
  DESCEDNING: "desc",
};
export const MEDICARE_ID = ["01192", "00882", "12302"];

export const PAID_CLAIM_SPANS_FORMAT = {
  weekly: "DDMMYYYY",
  monthly: "MMYYYY",
  yearly: "YYYY",
};
export const STATS_OBJECT = {
  approved: 0,
  approvedAmount: 0,
  denied: 0,
  deniedAmount: 0,
  draft: 0,
  draftAmount: 0,
  newPatients: 0,
  paid: 0,
  paidAmount: 0,
  partialProcessed: 0,
  partialProcessedAmount: 0,
  refundAmount: 0,
  rejectted: 0,
  rejecttedAmount: 0,
  submitted: 0,
  submittedAmount: 0,
};

export const STATUS_KEY = {
  draft: "draft",
  submitted: "submitted",
  rejected: "rejectted",
  approved: "approved",
  denied: "denied",
  paid: "paid",
  partial: "partialProcessed",
  cancelled: "cancelled",
};
export const API_ACTIONS = {
  fulfilled: "fulfilled",
  rejected: "rejected",
  pending: "pending",
};
export const ADMIN_ORDERS_DROPDOWN_OPTIONS = [{ value: "view", title: "View" }];
export const USER_TYPE_USER = "user";
export const USER_TYPE_ADMIN = "admin";
export const USER_TYPE_CLIENT = "client";
export const USER_TYPE_SUBAGENT = "subagent";

export const USER_ROLE_TITLE = {
  Admins: "Medflow Admin",
  Employers: "Facility/Agent Admin",
  Clients: "Client Admin",
  SubAgents: "Sub Agent Admin",
};
export const LAB_CLAIM_STATUS_OPT = [
  {
    value: "submitted",
    label: "Submitted",
  },
  { value: "denied", label: "Denied" },
  { value: "approved", label: "Approved" },
];
export const PARSE_PROVIDER = {
  renderingProvider: "Rendering Provider",
  referringProvider: "Referring Physician",
  orderingProvider: "Ordering Provider",
  referringPyhsician: "Referring Pyhsician",
  primaryCarePhysician: "Primary Care Physician",
};
export const DEFAULT_PROVIDERS_ARR = [
  "renderingProvider",
  "referringProvider",
  "orderingProvider",
  "referringPyhsician",
  "primaryCarePhysician",
];
export const DEFAULT_PROVIDERS = [
  {
    value: "renderingProvider",
    label: "Rendering Provider",
  },
  { value: "referringProvider", label: "Referring Physician" },
  { value: "orderingProvider", label: "Ordering Provider" },
  { value: "referringPyhsician", label: "Referring Pyhsician" },
  { value: "primaryCarePhysician", label: "Primary Care Physician" },
];
export const BILLING_TYPES_OPT = [
  { value: "mail", label: "Mail" },
  { value: "email", label: "Email" },
  { value: "fax", label: "Fax" },
];
export const TEST_ORDER_QUANTITY = [
  { label: 2, value: 2 },
  { label: 4, value: 4 },
  { label: 6, value: 6 },
  { label: 8, value: 8 },
];
// add new contant end

export const newRoleOptionsWhiteLabel = [
  { value: "Lab", label: "Admin", isWhiteLabelLab: "Yes" },
  { value: "Tester", label: "Tester", isWhiteLabelLab: "Yes" },
  { value: "LabTech", label: "Lab Tech", isWhiteLabelLab: "Yes" },
  // { value: "Employers", label: "Lab Client", isWhiteLabelLab: "Yes" },
  { value: "Provider", label: "Provider", isWhiteLabelLab: "Yes" },
];

export const TEST_RESULT_OPTIONS = [
  { value: "empty", label: "Not Resulted" },
  { value: "Positive", label: "Positive" },
  { value: "Negative", label: "Negative" },
];

export const crewRoleOptions = [
  { value: "Tester", label: "Tester" },
  { value: "Employee", label: "Crew" },
];

export const USER_ROLE_TYPE = {
  Admin: "Admin",
  Lab: "Lab",
  LabTech: "LabTech",
  Tester: "Tester",
  Employee: "Employee",
  Employer: "Employer",
  Client: "Client",
  Provider: "Provider",
};

export const FILE_TYPES = {
  pdf: ".pdf",
  csv: ".pdf",
  jpg: ".jpg",
  jpeg: ".jpeg",
  png: ".png",
  gif: ".gif",
  doc: ".doc",
  docx: ".docx",
};

export const DEFAULT_ACCEPTED_FILE_TYPES = ".pdf, .jpg, .jpeg, .png, .gif, .doc, .docx";

export const FILE_REPRESENT_TYPES_MAP = new Map([
  ["application/pdf", "pdf"],
  ["text/csv", "csv"],
]);

export const HEALTH_AND_SAFTEY = "Health & Safety";

export const RESULT_SENT_OPTIONS = [
  {
    value: "1",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" tickIcon />
      </div>
    ),
  },
  {
    value: "0",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];

export const CUSTODY_OPT = [
  { value: "", label: "All" },
  {
    value: "Received",
    label: "Received",
  },
  { value: "Sent Out", label: "Sent Out" },
  { value: "Specimen Collected", label: "Specimen Collected" },
];

export const GET_CUST_COLOR = {
  "At Location": "maroon",
  "Specimen Collected": "maroon",
  "Test Sent": "maroon",
  "Sent Out": "maroon",
  "Received By Lab": "green",
  "Sample Received": "green",
  "Test Shipped": "green",
  "Test Sent": "green",
};
export const EmptyUserObj = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "Employee",
  isNew: true,
  lab: null,
  show: null,
  client: null,
};

export const AMAZON_CLIENT_RESULT = {
  Positive: "Fail",
  Negative: "Pass",
  Expired: "Expired",
  positive: "Fail",
  negative: "Pass",
  expired: "Expired",
  Pass: "Pass",
  Fail: "Fail",
  pass: "Pass",
  fail: "Fail",
  inconclusive: "Inconclusive",
  Inconclusive: "Inconclusive",
  indeterminate: "Indeterminate",
  Indeterminate: "Indeterminate",
  invalid: "Invalid",
  Invalid: "Invalid",
  Detected: "Detected",
  Invalid: "Invalid",
};

export const RESULT_COLOR = {
  positive: "#bf1e2e",
  negative: "green",
  expired: "orange",
  inconclusive: "blue",
  Inconclusive: "blue",
  invalid: "blue",
  Invalid: "blue",
  indeterminate: "blue",
  Indeterminate: "blue",
  pass: "green",
  Cancel: "red",
  fail: "red",
  detail: "green",
  details: "green",
  Detected: "red",
  detected: "red",
  NotDetected: "green",
  "Not Detected": "green",
};

export const RESULT_COLOR_OPTIONS = [
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Orange",
    value: "orange",
  },
];

export const TEST_STATUS_COLOR = {
  Pending: "orange",
  Cancel: "maroon",
};

export const RESULT_VALUE = {
  Positive: "Positive",
  Negative: "Negative",
  positive: "Positive",
  negative: "Negative",
  Inconclusive: "Inconclusive",
  inconclusive: "Inconclusive",
  indeterminate: "Indeterminate",
  Indeterminate: "Indeterminate",
  invalid: "Invalid",
  Invalid: "Invalid",
  Pass: "Pass",
  pass: "Pass",
  Fail: "Fail",
  Detected: "Detected",
  detected: "Detected",
  NotDetected: "Not Detected",
  notdetected: "Not Detected",
  fail: "Fail",
};

export const stringConstant = {
  sequenceNo: CONFIG.isWhiteLabel ? "Accession No" : "Sequence Number",
  site: CONFIG.isWhiteLabel ? "Client" : "Site",
  sites: CONFIG.isWhiteLabel ? "Locations" : "Sites",
  show: CONFIG.isWhiteLabel ? "Client" : "Show",
  SafeCamp: CONFIG.isWhiteLabel ? "List" : "SafeCamp",
  accession_no: CONFIG.isWhiteLabel ? "accession_no" : "sequenceNo",
  barcode: CONFIG.isWhiteLabel ? "Accession No" : "Barocode",
};

export const RESULT_PDF = {
  positive: "Detected",
  detected: "Detected",
  negative: "Not Detected",
  invalid: "Invalid",
  inconclusive: "Inconclusive",
  indeterminate: "Indeterminate",
};

export const weekDaysOptions = [
  { value: "0", label: "Sunday" },
  { value: "1", label: "Monday" },
  { value: "2", label: "Tuesday" },
  { value: "3", label: "Wednesday" },
  { value: "4", label: "Thursday" },
  { value: "5", label: "Friday" },
  { value: "6", label: "Saturday" },
];

export const TestTypeOptions = [
  { value: "Antigen", label: "Antigen" },
  { value: "Molecular", label: "Molecular" },
];

export const TEST_TYPE_Duarations = [
  { testType: "PCR", hours: 495 },
  { testType: "Antigen", hours: 50 },
  { testType: "Molecular", hours: 60 },
];
export const WEEK_DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const GEN_TYPE_VALUE = {
  firstName: "First",
  lastName: "Last",
  phone_number: "Phone",
  email: "Email",
  id_number: "License",
};
export const SUPER_ADMIN_ID = ["cd8826f7-d1a6-446f-be49-1b086c380002", "20cafd4b-2671-4854-8389-4f185d60c69b"];

export const EXPECTED_QUARANTINE_DAYS = 10;

export const newRegisterObj = {
  first: "",
  last: "",
  dob: null,
  id_number: "",
  street: "",
  city: "",
  state: "",
  phone_number: "",
  sex: "",
  email: "",
  isVaccinated: null,
  isBooster: null,
  country: "United States",
  testSend: true,
  testSendQty: 1,
  showId: null,
  capacity: "Guest",
  isExternalTest: "",
  isoCode: "US",
};

export const TEST_CATEGORY_OPTIONS = [
  { label: "Histopathology", value: "Histopathology" },
  { label: "Urinalysis", value: "Urinalysis" },
  { label: "Urology", value: "Urology" },
  { label: "Phlebotomist (PBT)", value: "Phlebotomist (PBT)" },
  { label: "Clinical chemistry", value: "Clinical chemistry" },
  { label: "Coagulation", value: "Coagulation" },
  { label: "Virology", value: "Virology" },
  { label: "Respiratory System", value: "Respiratory System" },
  { label: "Parasitology", value: "Parasitology" },
  { label: "Blood transfusion", value: "Blood transfusion" },
  { label: "Microbiology", value: "Microbiology" },
  { label: "Respiratory", value: "Respiratory" },
  { label: "Respiratory", value: "Respiratory" },
  { label: "UTI", value: "UTI" },
  { label: "Immunohaematology", value: "Immunohaematology" },
  { label: "Cytogenetics", value: "Cytogenetics" },
  { label: "Cytology", value: "Cytology" },
  { label: "Immunology", value: "Immunology" },
  { label: "Toxicology", value: "Toxicology" },
  { label: "Cytogenetics", value: "Cytogenetics" },
  { label: "Surgical Pathology", value: "Surgical Pathology" },
  { label: "Microbiology", value: "Microbiology" },
  { label: "Respiratory", value: "Respiratory" },
  { label: "Molecular diagnostics", value: "Molecular diagnostics" },
  { label: "Urology", value: "Urology" },
  { label: "Hematology", value: "Hematology" },
  { label: "Dermatology/ Wound care", value: "Dermatology/ Wound care" },
];
export const TESTS_UNITS_SET = [
  { value: "mg/dL", label: "mg/dL" },
  { value: "g/dL", label: "g/dL" },
  { value: "IU/L", label: "IU/L" },
  { value: "x10E6/uL", label: "x10E6/uL" },
  { value: "%", label: "%" },
  { value: "fL", label: "fl" },
  { value: "pg", label: "pg" },
  { value: "mL/min/1.73", label: "mL/min/1.73" },
  { value: "mmol/L", label: "mmol/L" },
];

export const TEST_TYPE_OPTIONS = [
  { value: "Qualitative", label: "Qualitative" },
  { value: "Quantitative", label: "Quantitative" },
  { value: "Both", label: "Both" },
  { value: "External Source", label: "External Source" },
];

export const TEST_TYPE_OPTIONS_ONLY = [
  { value: "Qualitative", label: "Qualitative" },
  { value: "Quantitative", label: "Quantitative" },
];

export const TEST_SOURCE_OPTIONS = [
  { label: "Amniotic Fluid", value: "Amniotic Fluid" },
  { label: "Blood", value: "Blood" },
  { label: "Bone Marrow", value: "Bone Marrow" },
  { label: "CSF", value: "CSF" },
  { label: "Finger Nail", value: "Finger Nail" },
  { label: "Hair", value: "Hair" },
  { label: "Mid-turbinate swab", value: "Mid-turbinate swab" },
  { label: "Nail", value: "Nail" },
  { label: "Nasal", value: "Nasal" },
  { label: "Nasopharyngeal Swab", value: "Nasopharyngeal Swab" },
  { label: "Oral", value: "Oral" },
  { label: "Other Fluids", value: "Other Fluids" },
  { label: "Saliva", value: "Saliva" },
  { label: "Semen", value: "Semen" },
  { label: "Serum", value: "Serum" },
  { label: "Serum (preferred)", value: "Serum (preferred)" },
  { label: "Stool", value: "Stool" },
  { label: "Sweat", value: "Sweat" },
  { label: "SW", value: "SW" },
  { label: "Swab", value: "Swab" },
  { label: "Throat", value: "Throat" },
  { label: "Tissue", value: "Tissue" },
  { label: "Urine", value: "Urine" },
  { label: "Vaginal", value: "Vaginal" },
  { label: "Vaginal Swab", value: "Vaginal Swab" },
  { label: "Wound", value: "Wound" },
  { label: "Wound/Sore", value: "Wound/Sore" },
];

export const MESSAGE_SEND_TYPE = [
  { value: "E", label: "Email" },
  { value: "M", label: "Text Message" },
];

export const CANCEL_REASON_OPTIONS = [
  {
    value: "Clotted Sample",
    label: "Clotted Sample",
    title: "Blood samples may clot, which makes certain tests impossible.",
  },
  {
    value: "Duplicate Test Order",
    label: "Duplicate Test Order",
    title: "The same test has been ordered more than once, leading to cancellation of the extra order.",
  },
  {
    value: "Expired Sample",
    label: "Expired Sample",
    title: "The sample was not tested within the required timeframe, making it unusable.",
  },
  {
    value: "Improper Storage",
    label: "Improper Storage",
    title: "The sample was stored or transported incorrectly, affecting its integrity.",
  },
  {
    value: "Insurance/Authorization Issue",
    label: "Insurance/Authorization Issue",
    title: "The test cannot proceed due to insurance denial or lack of preauthorization.",
  },
  {
    value: "Instrument Failure",
    label: "Instrument Failure",
    title: "Equipment malfunction prevents the test from being performed.",
  },
  {
    value: "Other",
    label: "Other",
    title: "Other",
  },
  {
    value: "Patient Withdrawal",
    label: "Patient Withdrawal",
    title: "The patient or physician has withdrawn consent for the test.",
  },
  {
    value: "Regulatory Issues",
    label: "Regulatory Issues",
    title: "Certain tests may be cancelled due to non-compliance with regulatory guidelines or licensure restrictions.",
  },
  {
    value: "Requisition Error",
    label: "Requisition Error",
    title:
      "The test order contains errors or lacks critical information, such as missing test codes or unclear instructions.",
  },
  {
    value: "Sample Contamination",
    label: "Sample Contamination",
    title: "The sample is compromised (e.g., mixed with another substance, exposed to extreme conditions).",
  },
  {
    value: "Sample Labelling Error",
    label: "Sample Labelling Error",
    title: "The sample is mislabelled or lacks proper identification (e.g., missing patient ID or date).",
  },
  {
    value: "Test Not Indicated",
    label: "Test Not Indicated",
    title: "The test ordered is not appropriate for the patient or diagnosis.",
  },
  {
    value: "Incorrect Sample Type",
    label: "Incorrect Sample Type",
    title:
      "The sample provided does not match the type required for the specific test (e.g., serum instead of plasma).",
  },
  {
    value: "Specimen Rejection Criteria",
    label: "Specimen Rejection Criteria",
    title:
      "The sample does not meet specific laboratory criteria for acceptance, such as haemolysis or incorrect volume.",
  },
];

export const externalTypesOptions = CONFIG.isWhiteLabel
  ? [
      { value: "1", label: "PCR" },
      { value: "2", label: "Urine Culture" },
      { value: "3", label: "Cystoscopy" },
      { value: "4", label: "Urinalysis with Reflex" },
      { value: "5", label: "Urinalysis microscopic examination" },
      { value: "6", label: "Urinary bladder" },
      { value: "7", label: "Proteus vulgaris indole test" },
      { value: "8", label: "CBC - W/out Diff" },
      { value: "9", label: "CBC With Diff" },
      { value: "10", label: "Pelvic exam" },
      { value: "11", label: "Rectal exam" },
    ]
  : [
      { value: "Molecular", label: "Cue" },
      { value: "PCR", label: "PCR" },
      { value: "Antigen", label: "Antigen" },
      { value: "Other", label: "Rapid PCR" },
      { value: "Lucira", label: "Lucira" },
      { value: "RapidFlu", label: "Rapid Flu" },
    ];

export const TEST_TYPES = CONFIG.isWhiteLabel
  ? [{ value: "PCR", label: "PCR" }]
  : [
      { value: "Molecular", label: "Molecular" },
      { value: "PCR", label: "PCR" },
      { value: "Antigen", label: "Antigen" },
      { value: "Rapid PCR", label: "Rapid PCR" },
      { value: "Lucira", label: "Lucira" },
      { value: "Rapid Flu", label: "Rapid Flu" },
    ];

export const PRE_REGISTRATION_TEST_TYPE = [
  { value: "Lucira", label: "Lucira" },
  { value: "PCR", label: "PCR" },
  { value: "Antigen", label: "Antigen" },
];

export const DOCUMENT_TYPES_OPT = [
  { value: "Authorization Document", label: "Authorization" },
  { value: "External Requisition Document", label: "External Requisition" },
  { value: "Identification Document", label: "Identification" },
  { value: "Insurance Document", label: "Insurance" },
  { value: "Prescription Document", label: "Prescription" },
  { value: "Other Documents", label: "Other Documents" },
];

export const Audit_Parse = {
  OrderPlaced: "Order Placed",
  OrderShipped: "Order Shipped",
  OrderReturned: "Order Returned",
  OrderOrderReturn: "Order Returned",
  OrderDelivered: "Order Delivered",
  Eligibility: "Eligibility Checked",
  MemberUpdated: "Member Edited",
  MemberCreated: "New Patient",
  SubmitForBill: "Claim Submitted",
  RESubmitForBill: "Claim Re-Submitted",
  ERAUpdate: "ERA Update",
  ClaimDenied: "Claim Denied",
  OrderLimitReset: "Order Limit Reset",
  insuranceStatus: "Insurance Status",
  claimStatus: "Claim Status",
  claimStatusRejected: "Claim Rejected",
  claimRejected: "Claim Rejected",
  ClaimCancelled: "Claim Cancelled",
  ClaimRestored: "Claim Restored",
  ClaimRejected: "Claim Rejected",
  claimApproved: "Claim Approved",
  SendToInsurance: "Send To Insurance",
  SubmissionFailed: "Submission Failed",
  ClaimDenied: "Claim Denied",
  ClaimWriteOff: "Write Off",
  ClaimInvoice: "Invoice Generated",
  InvoiceSent: "Invoice Sent",
  InvoiceCancel: "Invoice Cancelled",
  ClaimPartiallyProcessed: "Claim Partially Processed",
  claimAmountReceived: "Claim Paid",
  claimAmountReturned: "Claim Returned",
  ClaimCreated: "Claim Created",
  ClaimUpdated: "Claim Updated",
  CreatedDocument: "Document Created",
  UpdateDocument: "Document Updated",
  DeletedDocument: "Document Deleted",
  OrderUpdate: "Order Update",
  Note: "Note",
  isAcknowledge: "Acknowledge",
  UpdateStatus: "Status",
  isAssigned: "Assigned",
  ClientUpdated: "Client Updated",
  ClientCreated: "Client Created",
  PayerCreated: "Payer Created",
  PayerUpdated: "Payer Updated",
  ProviderUpdated: "Provider Updated",
  ProviderCreated: "Provider Created",
};

export const TOTAL_TEST_HEADER = ["createdAt", "firstName", "lastName", "phoneNumber", "email", "result"];

export const getPreRegistrationLink = (id, location) => {
  let baseLocation = "portal";
  if (location) {
    if (location.includes("staging")) {
      baseLocation = "staging";
    } else if (location.includes("test")) {
      baseLocation = "test";
    }
  }
  return `https://${baseLocation}.safecampmd.com/register/${id}`;
};

export const PRE_REGISTRATION_STATUS = {
  1: "Test Ordered",
  5: "Pre Registered",
};

export const TEST_ORDER_QTY = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];

export const GENDER_TEXT = {
  M: "Male",
  F: "Female",
  X: "Not Specified",
  N: "Not Binary",
};
export const GENDER_OPTIONS = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "X", label: "Not Specified" },
  { value: "N", label: "Not Binary" },
];

export const FILTER_SHOW_STATUS = [
  { value: "1", label: "Active" },
  { value: "0", label: "Pending" },
  { value: "2", label: "Hold" },
];

export const FILTER_TEST_STATUS = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Hold", label: "Hold" },
];

export const TAB_NAMES = {
  pendingResult: "Test",
  completedResult: "Test",
  orders: "Order",
  patient: "General",
};

export const ORDER_CANCELLED_RESASON_OPT = [
  { value: "COVID positive", label: "COVID positive" },
  { value: "Discharged", label: "Discharged" },
  { value: "Refused", label: "Refused" },
  { value: "Out of Facility", label: "Out of Facility" },
];

export const LAB_ORDER_STATUS = {
  Created: "1",
  Error: "2",
};

export const DICTIONARY_COLUMNS = [
  {
    itemKey: "label",
    title: "Title",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 0,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: "value",
    title: "Code",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const SOURCE_DICTIONARY_COLUMNS = [
  {
    itemKey: "label",
    title: "Source",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "code",
    title: "Label Code",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const INSTRUMENT_PLATFORM_COLUMNS = [
  {
    itemKey: "title",
    title: "Instrument",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "label",
    title: "Platform",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];
export const SPECIMEN_CONTAINER_COLUMNS = [
  {
    itemKey: "value",
    title: "Name",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "code",
    title: "Type",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const CANCELLED_DICTIONARY_COLUMNS = [
  {
    itemKey: "label",
    title: "Label",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "title",
    title: "Title",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];
export const RESULT_OPTIONS_COLUMNS = [
  {
    // itemKey: "label",
    // title: "Label",
    itemKey : "label",
    title: "Name",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "code",
    title: "Type",
    textAlign: "start",
    isCheck: true,
    textOverflow: "visible",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const PRE_REG_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Date Created ",
    textAlign: "start",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "first",
    title: "First Name",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    id: 2,
  },
  {
    itemKey: "last",
    title: "Last Name",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    id: 3,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "phone_number",
    title: "Phone Number",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    id: 5,
  },
  {
    itemKey: "sex",
    title: "Gender",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    id: 6,
  },
  {
    itemKey: "id_number",
    title: "ID Number",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    id: 7,
  },
  {
    itemKey: "dob",
    title: "Date Of Birth",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    id: 8,
  },
  {
    itemKey: "street",
    title: "Street",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    id: 9,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    id: 10,
  },
  {
    itemKey: "isVaccinated",
    title: "Vaccinated",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    id: 11,
  },
  {
    itemKey: "vaccinationType",
    title: "Vaccination Type",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    id: 12,
  },
  {
    itemKey: "vaccinationDate",
    title: "Vaccination Date",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 13,
  },
  {
    itemKey: "vaccinationLocation",
    title: "Vaccination Location",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 14,
  },
  {
    itemKey: "showName",
    title: "Show Name",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    id: 15,
  },
  {
    itemKey: "testSendQty",
    title: "Order Quantity",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    id: 16,
  },
  {
    itemKey: "step",
    title: "Status",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    id: 17,
  },
  {
    itemKey: "orderId",
    title: "SB Order Id",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 18,
  },
  {
    itemKey: "orderStatus",
    title: "SB Order Status",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 19,
  },
  {
    itemKey: "isExternalTest",
    title: "External Test",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 20,
  },
  {
    itemKey: "externalTestType",
    title: "External Test Type",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 21,
  },
  {
    itemKey: "externalTestDate",
    title: "External Test Date",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 22,
  },
  {
    itemKey: "externalTestResult",
    title: "External Test Result",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 23,
  },
  {
    itemKey: "capacity",
    title: "Capacity",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 24,
  },
  {
    itemKey: "designation",
    title: "Role",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.firstLetterCapital,
    id: 24,
  },
  {
    itemKey: "orderKitType",
    title: "Test Type",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 25,
  },
  {
    itemKey: "testID",
    title: "Test ID",
    isCheck: true,
    textAlign: "centered",
    textOverflow: "none",
    id: 26,
  },
];

export const defaultPersonalization = () => {
  return { preregistration: getPersonalizationIds("preregistration"), employee: getPersonalizationIds("employee") };
};

export const EMPS_DROPDOWN_OPTIONS = [
  { value: "logs", title: "Logs" },
  { value: "delete", title: "Delete" },
];

export const ROUTES = ["preregistration", "users", "clients", "shows", "testtypes"];

export const PERSONALISATION_KEY = {
  PRE_REGISTRATION: "preregistration",
  EMPLOYEE: "employee",
  LAB_CLIENT: "labClient",
  INSURANCE: "insurance",
  PROVIDER: "provider",
  PENDING_TEST: "pendingtest",
  COMPLETED_TEST: "completedtest",
  ORDER_PERSONALIZE: "orderpersonalize",
  TEST_DETAILS_DONE: "testDetailsDone",
  TEST_HISTORY: "testHistory",
  PANEL: "panel",
};

// Delivery Courier Company data
export const RESULT_CHECK = {
  inconclusive: "inconclusive",
  invalid: "invalid",
  positive: "positive",
};

export const PRE_REG_QR_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Date Created ",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "first",
    title: "First Name",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.upperCaseText,
    id: 2,
  },
  {
    itemKey: "last",
    title: "Last Name",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    type: TABLE_COLUMN_TYPES.upperCaseText,
    id: 3,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "phone_number",
    title: "Phone Number",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    id: 5,
  },
  {
    itemKey: "dob",
    title: "Date Of Birth",
    textAlign: "centered",
    textOverflow: "none",
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "id_number",
    title: "ID Number",
    textAlign: "centered",
    isCheck: true,
    textOverflow: "none",
    id: 7,
  },
];

export const DELIVERY_COMPANY = [
  { value: "FedEx", label: "FedEx" },
  // { value: "USPS", label: "USPS" },
  { value: "UPS", label: "UPS" },
  // { value: "Blue_Dart", label: "Blue Dart" },
  { value: "DHL", label: "DHL" },
  // { value: "Central_Courier", label: "Central Courier" },
  // { value: "LaserShip", label: "LaserShip" },
  // { value: "Aramex", label: "Aramex" },
  // { value: "SkyPostal", label: "SkyPostal" },
];
export const DISCLAIMER_TYPE = {
  DISCLAIMER: "DISCLAIMER",
  ANTIGEN: "ANTIGEN",
  MOLECULAR: "MOLECULAR",
  ACCULA: "ACCULA",
  LUCIRA: "LUCIRA",
  RAPIDFLU: "RAPIDFLU",
  CUSTOM: "CUSTOM",
  INVALIDNOTES: "INVALIDNOTES",
  INDERMINATENOTES: "INDERMINATENOTES",
};

export const DISCLAIMER_KEYS = {
  DISCLAIMER: "testing_notes",
  ANTIGEN: "antigen_notes",
  MOLECULAR: "molecular_notes",
  ACCULA: "other_notes",
  LUCIRA: "lucira_notes",
  RAPIDFLU: "rapidflu_notes",
  CUSTOM: "custom_notes",
  INDERMINATENOTES: "indeterminate_notes",
  INVALIDNOTES: "invalid_notes",
};

export const TEST_TYPE_DISCLAIMER_KEY = {
  Lucira: "lucira_notes",
  PCR: "testing_notes",
  Rapid_Antigen: "antigen_notes",
  Rapid_PCR: "other_notes",
  Molecular: "molecular_notes",
};

export const ADDRESS_OPTIONS = {
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};

export const countryListAllIsoData = [
  { code: "AF", code3: "AFG", name: "Afghanistan", number: "004" },
  { code: "AL", code3: "ALB", name: "Albania", number: "008" },
  { code: "DZ", code3: "DZA", name: "Algeria", number: "012" },
  { code: "AS", code3: "ASM", name: "American Samoa", number: "016" },
  { code: "AD", code3: "AND", name: "Andorra", number: "020" },
  { code: "AO", code3: "AGO", name: "Angola", number: "024" },
  { code: "AI", code3: "AIA", name: "Anguilla", number: "660" },
  { code: "AQ", code3: "ATA", name: "Antarctica", number: "010" },
  { code: "AG", code3: "ATG", name: "Antigua and Barbuda", number: "028" },
  { code: "AR", code3: "ARG", name: "Argentina", number: "032" },
  { code: "AM", code3: "ARM", name: "Armenia", number: "051" },
  { code: "AW", code3: "ABW", name: "Aruba", number: "533" },
  { code: "AU", code3: "AUS", name: "Australia", number: "036" },
  { code: "AT", code3: "AUT", name: "Austria", number: "040" },
  { code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031" },
  { code: "BS", code3: "BHS", name: "Bahamas", number: "044" },
  { code: "BH", code3: "BHR", name: "Bahrain", number: "048" },
  { code: "BD", code3: "BGD", name: "Bangladesh", number: "050" },
  { code: "BB", code3: "BRB", name: "Barbados", number: "052" },
  { code: "BY", code3: "BLR", name: "Belarus", number: "112" },
  { code: "BE", code3: "BEL", name: "Belgium", number: "056" },
  { code: "BZ", code3: "BLZ", name: "Belize", number: "084" },
  { code: "BJ", code3: "BEN", name: "Benin", number: "204" },
  { code: "BM", code3: "BMU", name: "Bermuda", number: "060" },
  { code: "BT", code3: "BTN", name: "Bhutan", number: "064" },
  { code: "BO", code3: "BOL", name: "Bolivia", number: "068" },
  { code: "BQ", code3: "BES", name: "Bonaire, Sint Eustatius and Saba", number: "535" },
  { code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", number: "070" },
  { code: "BW", code3: "BWA", name: "Botswana", number: "072" },
  { code: "BV", code3: "BVT", name: "Bouvet Island", number: "074" },
  { code: "BR", code3: "BRA", name: "Brazil", number: "076" },
  { code: "IO", code3: "IOT", name: "British Indian Ocean Territory", number: "086" },
  { code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096" },
  { code: "BG", code3: "BGR", name: "Bulgaria", number: "100" },
  { code: "BF", code3: "BFA", name: "Burkina Faso", number: "854" },
  { code: "BI", code3: "BDI", name: "Burundi", number: "108" },
  { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132" },
  { code: "KH", code3: "KHM", name: "Cambodia", number: "116" },
  { code: "CM", code3: "CMR", name: "Cameroon", number: "120" },
  { code: "CA", code3: "CAN", name: "Canada", number: "124" },
  { code: "KY", code3: "CYM", name: "Cayman Islands", number: "136" },
  { code: "CF", code3: "CAF", name: "Central African Republic", number: "140" },
  { code: "TD", code3: "TCD", name: "Chad", number: "148" },
  { code: "CL", code3: "CHL", name: "Chile", number: "152" },
  { code: "CN", code3: "CHN", name: "China", number: "156" },
  { code: "CX", code3: "CXR", name: "Christmas Island", number: "162" },
  { code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands", number: "166" },
  { code: "CO", code3: "COL", name: "Colombia", number: "170" },
  { code: "KM", code3: "COM", name: "Comoros", number: "174" },
  { code: "CD", code3: "COD", name: "Congo, The Democratic Republic of the", number: "180" },
  { code: "CG", code3: "COG", name: "Congo", number: "178" },
  { code: "CK", code3: "COK", name: "Cook Islands", number: "184" },
  { code: "CR", code3: "CRI", name: "Costa Rica", number: "188" },
  { code: "HR", code3: "HRV", name: "Croatia", number: "191" },
  { code: "CU", code3: "CUB", name: "Cuba", number: "192" },
  { code: "CW", code3: "CUW", name: "Curaçao", number: "531" },
  { code: "CY", code3: "CYP", name: "Cyprus", number: "196" },
  { code: "CZ", code3: "CZE", name: "Czechia", number: "203" },
  { code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384" },
  { code: "DK", code3: "DNK", name: "Denmark", number: "208" },
  { code: "DJ", code3: "DJI", name: "Djibouti", number: "262" },
  { code: "DM", code3: "DMA", name: "Dominica", number: "212" },
  { code: "DO", code3: "DOM", name: "Dominican Republic", number: "214" },
  { code: "EC", code3: "ECU", name: "Ecuador", number: "218" },
  { code: "EG", code3: "EGY", name: "Egypt", number: "818" },
  { code: "SV", code3: "SLV", name: "El Salvador", number: "222" },
  { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226" },
  { code: "ER", code3: "ERI", name: "Eritrea", number: "232" },
  { code: "EE", code3: "EST", name: "Estonia", number: "233" },
  { code: "SZ", code3: "SWZ", name: "Eswatini", number: "748" },
  { code: "ET", code3: "ETH", name: "Ethiopia", number: "231" },
  { code: "FK", code3: "FLK", name: "Falkland Islands (Malvinas)", number: "238" },
  { code: "FO", code3: "FRO", name: "Faroe Islands", number: "234" },
  { code: "FJ", code3: "FJI", name: "Fiji", number: "242" },
  { code: "FI", code3: "FIN", name: "Finland", number: "246" },
  { code: "FR", code3: "FRA", name: "France", number: "250" },
  { code: "GF", code3: "GUF", name: "French Guiana", number: "254" },
  { code: "PF", code3: "PYF", name: "French Polynesia", number: "258" },
  { code: "TF", code3: "ATF", name: "French Southern Territories", number: "260" },
  { code: "GA", code3: "GAB", name: "Gabon", number: "266" },
  { code: "GM", code3: "GMB", name: "Gambia", number: "270" },
  { code: "GE", code3: "GEO", name: "Georgia", number: "268" },
  { code: "DE", code3: "DEU", name: "Germany", number: "276" },
  { code: "GH", code3: "GHA", name: "Ghana", number: "288" },
  { code: "GI", code3: "GIB", name: "Gibraltar", number: "292" },
  { code: "GR", code3: "GRC", name: "Greece", number: "300" },
  { code: "GL", code3: "GRL", name: "Greenland", number: "304" },
  { code: "GD", code3: "GRD", name: "Grenada", number: "308" },
  { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312" },
  { code: "GU", code3: "GUM", name: "Guam", number: "316" },
  { code: "GT", code3: "GTM", name: "Guatemala", number: "320" },
  { code: "GG", code3: "GGY", name: "Guernsey", number: "831" },
  { code: "GN", code3: "GIN", name: "Guinea", number: "324" },
  { code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624" },
  { code: "GY", code3: "GUY", name: "Guyana", number: "328" },
  { code: "HT", code3: "HTI", name: "Haiti", number: "332" },
  { code: "HM", code3: "HMD", name: "Heard Island and McDonald Islands", number: "334" },
  { code: "VA", code3: "VAT", name: "Holy See (Vatican City State)", number: "336" },
  { code: "HN", code3: "HND", name: "Honduras", number: "340" },
  { code: "HK", code3: "HKG", name: "Hong Kong", number: "344" },
  { code: "HU", code3: "HUN", name: "Hungary", number: "348" },
  { code: "IS", code3: "ISL", name: "Iceland", number: "352" },
  { code: "IN", code3: "IND", name: "India", number: "356" },
  { code: "ID", code3: "IDN", name: "Indonesia", number: "360" },
  { code: "IR", code3: "IRN", name: "Iran, Islamic Republic Of", number: "364" },
  { code: "IQ", code3: "IRQ", name: "Iraq", number: "368" },
  { code: "IE", code3: "IRL", name: "Ireland", number: "372" },
  { code: "IM", code3: "IMN", name: "Isle of Man", number: "833" },
  { code: "IL", code3: "ISR", name: "Israel", number: "376" },
  { code: "IT", code3: "ITA", name: "Italy", number: "380" },
  { code: "JM", code3: "JAM", name: "Jamaica", number: "388" },
  { code: "JP", code3: "JPN", name: "Japan", number: "392" },
  { code: "JE", code3: "JEY", name: "Jersey", number: "832" },
  { code: "JO", code3: "JOR", name: "Jordan", number: "400" },
  { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398" },
  { code: "KE", code3: "KEN", name: "Kenya", number: "404" },
  { code: "KI", code3: "KIR", name: "Kiribati", number: "296" },
  { code: "KP", code3: "PRK", name: "Korea, Democratic People's Republic of", number: "408" },
  { code: "KR", code3: "KOR", name: "Korea, Republic of", number: "410" },
  { code: "KW", code3: "KWT", name: "Kuwait", number: "414" },
  { code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417" },
  { code: "LA", code3: "LAO", name: "Lao People's Democratic Republic", number: "418" },
  { code: "LV", code3: "LVA", name: "Latvia", number: "428" },
  { code: "LB", code3: "LBN", name: "Lebanon", number: "422" },
  { code: "LS", code3: "LSO", name: "Lesotho", number: "426" },
  { code: "LR", code3: "LBR", name: "Liberia", number: "430" },
  { code: "LY", code3: "LBY", name: "Libya", number: "434" },
  { code: "LI", code3: "LIE", name: "Liechtenstein", number: "438" },
  { code: "LT", code3: "LTU", name: "Lithuania", number: "440" },
  { code: "LU", code3: "LUX", name: "Luxembourg", number: "442" },
  { code: "MO", code3: "MAC", name: "Macao", number: "446" },
  { code: "MG", code3: "MDG", name: "Madagascar", number: "450" },
  { code: "MW", code3: "MWI", name: "Malawi", number: "454" },
  { code: "MY", code3: "MYS", name: "Malaysia", number: "458" },
  { code: "MV", code3: "MDV", name: "Maldives", number: "462" },
  { code: "ML", code3: "MLI", name: "Mali", number: "466" },
  { code: "MT", code3: "MLT", name: "Malta", number: "470" },
  { code: "MH", code3: "MHL", name: "Marshall Islands", number: "584" },
  { code: "MQ", code3: "MTQ", name: "Martinique", number: "474" },
  { code: "MR", code3: "MRT", name: "Mauritania", number: "478" },
  { code: "MU", code3: "MUS", name: "Mauritius", number: "480" },
  { code: "YT", code3: "MYT", name: "Mayotte", number: "175" },
  { code: "MX", code3: "MEX", name: "Mexico", number: "484" },
  { code: "FM", code3: "FSM", name: "Micronesia, Federated States of", number: "583" },
  { code: "MD", code3: "MDA", name: "Moldova, Republic of", number: "498" },
  { code: "MC", code3: "MCO", name: "Monaco", number: "492" },
  { code: "MN", code3: "MNG", name: "Mongolia", number: "496" },
  { code: "ME", code3: "MNE", name: "Montenegro", number: "499" },
  { code: "MS", code3: "MSR", name: "Montserrat", number: "500" },
  { code: "MA", code3: "MAR", name: "Morocco", number: "504" },
  { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508" },
  { code: "MM", code3: "MMR", name: "Myanmar", number: "104" },
  { code: "NA", code3: "NAM", name: "Namibia", number: "516" },
  { code: "NR", code3: "NRU", name: "Nauru", number: "520" },
  { code: "NP", code3: "NPL", name: "Nepal", number: "524" },
  { code: "NL", code3: "NLD", name: "Netherlands", number: "528" },
  { code: "NC", code3: "NCL", name: "New Caledonia", number: "540" },
  { code: "NZ", code3: "NZL", name: "New Zealand", number: "554" },
  { code: "NI", code3: "NIC", name: "Nicaragua", number: "558" },
  { code: "NE", code3: "NER", name: "Niger", number: "562" },
  { code: "NG", code3: "NGA", name: "Nigeria", number: "566" },
  { code: "NU", code3: "NIU", name: "Niue", number: "570" },
  { code: "NF", code3: "NFK", name: "Norfolk Island", number: "574" },
  { code: "MP", code3: "MNP", name: "Northern Mariana Islands", number: "580" },
  { code: "NO", code3: "NOR", name: "Norway", number: "578" },
  { code: "OM", code3: "OMN", name: "Oman", number: "512" },
  { code: "PK", code3: "PAK", name: "Pakistan", number: "586" },
  { code: "PW", code3: "PLW", name: "Palau", number: "585" },
  { code: "PS", code3: "PSE", name: "Palestinian Territory, Occupied", number: "275" },
  { code: "PA", code3: "PAN", name: "Panama", number: "591" },
  { code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598" },
  { code: "PY", code3: "PRY", name: "Paraguay", number: "600" },
  { code: "PE", code3: "PER", name: "Peru", number: "604" },
  { code: "PH", code3: "PHL", name: "Philippines", number: "608" },
  { code: "PN", code3: "PCN", name: "Pitcairn", number: "612" },
  { code: "PL", code3: "POL", name: "Poland", number: "616" },
  { code: "PT", code3: "PRT", name: "Portugal", number: "620" },
  { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630" },
  { code: "QA", code3: "QAT", name: "Qatar", number: "634" },
  { code: "MK", code3: "MKD", name: "Macedonia, The Former Yugoslav Republic of", number: "807" },
  { code: "RO", code3: "ROU", name: "Romania", number: "642" },
  { code: "RU", code3: "RUS", name: "Russian Federation", number: "643" },
  { code: "RW", code3: "RWA", name: "Rwanda", number: "646" },
  { code: "RE", code3: "REU", name: "Reunion", number: "638" },
  { code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652" },
  { code: "SH", code3: "SHN", name: "Saint Helena", number: "654" },
  { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659" },
  { code: "LC", code3: "LCA", name: "Saint Lucia", number: "662" },
  { code: "MF", code3: "MAF", name: "Saint Martin (French part)", number: "663" },
  { code: "PM", code3: "SPM", name: "Saint Pierre and Miquelon", number: "666" },
  { code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", number: "670" },
  { code: "WS", code3: "WSM", name: "Samoa", number: "882" },
  { code: "SM", code3: "SMR", name: "San Marino", number: "674" },
  { code: "ST", code3: "STP", name: "Sao Tome and Principe", number: "678" },
  { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682" },
  { code: "SN", code3: "SEN", name: "Senegal", number: "686" },
  { code: "RS", code3: "SRB", name: "Serbia", number: "688" },
  { code: "SC", code3: "SYC", name: "Seychelles", number: "690" },
  { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694" },
  { code: "SG", code3: "SGP", name: "Singapore", number: "702" },
  { code: "SX", code3: "SXM", name: "Sint Maarten (Dutch part)", number: "534" },
  { code: "SK", code3: "SVK", name: "Slovakia", number: "703" },
  { code: "SI", code3: "SVN", name: "Slovenia", number: "705" },
  { code: "SB", code3: "SLB", name: "Solomon Islands", number: "090" },
  { code: "SO", code3: "SOM", name: "Somalia", number: "706" },
  { code: "ZA", code3: "ZAF", name: "South Africa", number: "710" },
  { code: "GS", code3: "SGS", name: "South Georgia and the South Sandwich Islands", number: "239" },
  { code: "SS", code3: "SSD", name: "South Sudan", number: "728" },
  { code: "ES", code3: "ESP", name: "Spain", number: "724" },
  { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144" },
  { code: "SD", code3: "SDN", name: "Sudan", number: "729" },
  { code: "SR", code3: "SUR", name: "Suriname", number: "740" },
  { code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", number: "744" },
  { code: "SE", code3: "SWE", name: "Sweden", number: "752" },
  { code: "CH", code3: "CHE", name: "Switzerland", number: "756" },
  { code: "SY", code3: "SYR", name: "Syrian Arab Republic", number: "760" },
  { code: "TW", code3: "TWN", name: "Taiwan", number: "158" },
  { code: "TJ", code3: "TJK", name: "Tajikistan", number: "762" },
  { code: "TZ", code3: "TZA", name: "Tanzania, United Republic of", number: "834" },
  { code: "TH", code3: "THA", name: "Thailand", number: "764" },
  { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626" },
  { code: "TG", code3: "TGO", name: "Togo", number: "768" },
  { code: "TK", code3: "TKL", name: "Tokelau", number: "772" },
  { code: "TO", code3: "TON", name: "Tonga", number: "776" },
  { code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780" },
  { code: "TN", code3: "TUN", name: "Tunisia", number: "788" },
  { code: "TR", code3: "TUR", name: "Turkey", number: "792" },
  { code: "TM", code3: "TKM", name: "Turkmenistan", number: "795" },
  { code: "TC", code3: "TCA", name: "Turks and Caicos Islands", number: "796" },
  { code: "TV", code3: "TUV", name: "Tuvalu", number: "798" },
  { code: "UG", code3: "UGA", name: "Uganda", number: "800" },
  { code: "UA", code3: "UKR", name: "Ukraine", number: "804" },
  { code: "AE", code3: "ARE", name: "United Arab Emirates", number: "784" },
  { code: "GB", code3: "GBR", name: "United Kingdom", number: "826" },
  { code: "UM", code3: "UMI", name: "United States Minor Outlying Islands", number: "581" },
  { code: "US", code3: "USA", name: "United States", number: "840" },
  { code: "UY", code3: "URY", name: "Uruguay", number: "858" },
  { code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860" },
  { code: "VU", code3: "VUT", name: "Vanuatu", number: "548" },
  { code: "VE", code3: "VEN", name: "Venezuela", number: "862" },
  { code: "VN", code3: "VNM", name: "Vietnam", number: "704" },
  { code: "VG", code3: "VGB", name: "Virgin Islands, British", number: "092" },
  { code: "VI", code3: "VIR", name: "Virgin Islands, U.S.", number: "850" },
  { code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876" },
  { code: "EH", code3: "ESH", name: "Western Sahara", number: "732" },
  { code: "YE", code3: "YEM", name: "Yemen", number: "887" },
  { code: "ZM", code3: "ZMB", name: "Zambia", number: "894" },
  { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716" },
  { code: "AX", code3: "ALA", name: "Åland Islands", number: "248" },
];

export const TEST_EMPTY_OBJECT = {
  employee_demographics: {
    email: "",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "+1",
    address: "",
    employeeType: "Staff",
    isVaccinated: false,
    whiteGlove: false,
    isoCode: "US",
    city: "",
    country: "",
    state: "",
    street: "",
    street2: "",
    zip: "",
    isLucira: false,
    sex: "",
    testerDes: "",
    idNumber: "",
  },
  createdAt: "",
  result: null,
  sequenceNo: "",
  clientID: "",
  clientName: "",
  siteID: "",
  site_name: "",
  labID: "",
  labName: "",
  test_type: "PCR",
  isLucira: false,
  emailSend: false,
  isFalsePositive: false,
  phoneNumber: "",
  email: "",
  employeeID: "",
  status: "Pending",
  result: "No Result",
  testerPhone: "",
  tester_name: "",
  releaseDate: "",
};

export const ORDER_EMPTY_OBJECT = {
  employee_demographics: {
    email: "",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "+1",
    address: "",
    isoCode: "US",
    city: "",
    country: "",
    state: "",
    street: "",
    street2: "",
    zip: "",
    sex: "",
    idNumber: "",
  },
  createdAt: "",
  result: null,
  sequenceNo: "",
  clientID: "",
  clientName: "",
  siteID: "",
  site_name: "",
  test_type: "PCR",
  isNew: true,
  phoneNumber: "",
  email: "",
  employeeID: "",
  status: "Pending",
  testerPhone: "",
  tester_name: "",
};

export const TRANSITION_STATUS_OPT = [
  { value: "Accepted", label: "Accepted" },
  { value: "In-transit", label: "In-transit" },
  { value: "Pending", label: "Pending" },
];

export const GRAPH_COLOR_VALUES = [
  "rgb(72, 139, 194)",
  "rgb(96, 79, 951)",
  "rgb(139, 12, 129)",
  "rgb(199, 18, 140)",
  "rgb(159, 190, 87)",
  "rgb(210, 179, 63)",
  "rgb(131, 126, 49)",
  "rgb(417, 33, 93)",
  "rgb(210, 99, 12)",
  "rgb(174, 91, 12)",
  "rgb(227, 18, 23)",
  "rgb(217, 33, 62)",
  "rgb(217, 163, 152)",
  "rgb(120, 28, 129)",
  "rgba(255, 99, 132, 200)",
  "rgba(54, 162, 235, 400)",
  "rgba(255, 206, 86, 300)",
  "rgba(75, 192, 192, 15)",
  "rgba(153, 102, 255, 76)",
  "rgba(255, 159, 64, 912)",
  "rgb(64, 67, 153)",
  "rgb(72, 139, 194)",
  "rgb(107, 178, 140)",
  "rgb(159, 190, 87)",
  "rgb(210, 179, 63)",
  "rgb(231, 126, 49)",
  "rgb(217, 33, 23)",
  "rgb(210, 33, 12)",
  "rgb(117, 13, 12)",
  "rgb(227, 18, 23)",
  "rgb(217, 33, 62)",
  "rgb(713, 23,534)",
  "rgb(545, 234, 341)",
  "rgb(570, 8, 42)",
  "rgb(171, 65, 34)",
  "rgb(874, 28, 21)",
  "rgb(172, 13, 19)",
  "rgb(324, 45, 43)",
  "rgb(435, 23, 84)",
  "rgb(143, 67, 98)",
  "rgb(317, 23, 32)",
  "rgb(117, 73, 82)",
  "rgb(181, 128, 229)",
  "rgb(112, 108, 149)",
  "rgb(132, 112, 987)",
  "rgb(190, 198, 876)",
  "rgb(100, 280, 290)",
  "rgb(145, 182, 312)",
  "rgb(900, 432, 672)",
  "rgb(233, 984, 111)",
  "rgb(340,190, 789)",
  "rgb(353, 176, 349)",
  "rgb(654, 532, 785)",
  "rgb(384, 763, 346)",
  "rgb(263, 943, 439)",
  "rgb(893, 675, 653)",
];

export const GRAPH_WEEKDAYS_LABELS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const sliderSettings = [
  {
    // breakpoint: 1366,
    settings: {
      loop: false,
      autoPlay: true,
      infinite: false,
      speed: 500,
    },
  },
];

export const LAB_ADMIN_ROLE = ["Admin", "Client", "Lab"];

export const ADMIN_USER = ["+18888888888", "+12222222222", "+18322958840"];

export const EDIT_PERMISSION_ROLE = ["Admins", "Labs", "LabTechs", "LabTech", "Lab"];

export const RESULT_SETTINGS_OPT = [
  { value: "Inconclusive", label: "Inconclusive" },
  { value: "Invalid", label: "Invalid" },
  { value: "Indeterminate", label: "Indeterminate" },
  { value: "Detected", label: "Detected" },
  { value: "Not Detected", label: "Not Detected" },
];
export const RESULT_SETTINGS_OPT_QUALITATIVE = [
  { value: "Invalid", label: "Invalid" },
  { value: "Indeterminate", label: "Indeterminate" },
  { value: "Detected", label: "Detected" },
  { value: "Not Detected", label: "Not Detected" },
];

export const NOT_INC_KEYS = [
  "updatedBy",
  "updatedByName",
  "_version",
  "employee_demographics",
  "updatedAt",
  "clientID",
  "siteID",
  "labID",
  "createdBy",
  "barcode",
  "status",
  "submittedBy",
  "submittedByName",
  "StampBy",
  "StampByName",
  "emailSend",
  "resultDate",
  "resultDate",
  "resultDateTime",
  "labelPrinted",
  "insuranceDetails",
  "scanByTesterID",
  "scanByTesterName",
  "scanByLabUserID",
  "scanByLabUserName",
];

export const TEST_KEYS_PARSE = {
  id: "ID",
  first: "First Name",
  last: "Last Name",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  dob: "DateOfBirth",
  phoneNumber: "Phone",
  clientID: "Client",
  siteID: "Show",
  site_name: "Show",
  labID: "Lab",
  status: "Status",
  result: "Result",
  test_type: "Test Type",
  reason: "Reason",
  sequenceNo: "Sequence",
  tester_name: "Tester",
  emailSend: "Result Sent",
  updatedAt: "Date Processed",
  createdAt: "Date Collected",
  resultDate: "Date Processed",
  batch: "Date Tested",
  validUntil: "Valid Until",
  isQuarantined: "Quarantined",
  show: "Show",
  resultDates: "Last Positive Test Date",
  quarantinedStart: "Expected End Date",
  daysCount: "Days Count",
  hippaConsent: "Hippa Consent",
  referenceID: "ReferenceID",
  accession_no: "Accession No",
  phone_number: "Phone",
  sex: "Gender",
  id_number: "ID Number",
  street: "Street",
  city: "City",
  showName: "Show Name",
  testSendQty: "Send Quantity",
  isVaccinated: "Vaccinated",
  vaccinationType: "Vaccination Type",
  vaccinationDate: "Vaccination Date",
  isFalsePositive: "isFalsePositive",
  isExternalTest: "External Test",
  externalTestType: "External Test Type",
  externalTestDate: "External Test Date",
  externalTestResult: "External Test Resut",
  step: "Status",
  orderStatus: "Order Status",
  barcode: "Barcode",
  employeeType: "Employee Type",
  capcity: "Capacity",
  designation: "Designation",
  whiteGlove: "White Glove",
  patternTestAnswer: "Pattern Test",
};

export let IR_COLUMN_INDEX = {
  Well: 0,
  Fluor: 1,
  Target: 2,
  Content: 3,
  Sample: 4,
  Cq: 5,
  Call: 6,
};

export const RESULTS_COLOR = {
  positive: "red",
  negative: "black",
  invalid: "red",
  inconclusive: "red",
  indeterminate: "red",
  detected: "red",
};

export const SYMPTOMS = {
  nasal: "nasal",
  oral: "oral",
  yes: "yes",
  no: "no",
  fever: "FEVER / CHILLS",
  cough: "COUGH",
  headache: "HEADACHE",
  fatigue: "FATIGUE",
  soreThroat: "SORE THROAT",
  diarrhea: "DIARRHEA",
  shortnessOfBreath: "SHORTNESS OF BREATH",
  muscelBodyAches: "MUSCLE / BODY ACHES",
  lossOfTaste: "NEW LOSS OF TASTE / SMELL",
  congestion: "CONGESTION / RUNNY NOSE",
  nausea: "NAUSEA / VOMITING",
  noSymptoms: "NO SYMPTOMS / ASYMPTOMATIC",
  other: "OTHER",
};

export const EXPORT_TO_CALREDIE = {
  patient_id: "",
  patient_last_name: "",
  patient_first_name: "",
  patient_middle_name: "",
  patient_street: "",
  patient_street2: "",
  patient_city: "",
  patient_state: "",
  patient_zip_code: "",
  patient_county: "",
  patient_phone_number: "",
  patient_dob: "",
  patient_gender: "",
  patient_race: "UNK",
  patient_ethnicity: "UNK",
  patient_preferred_language: "",
  patient_email: "",
  accession_number: "",
  equipment_model_name: "Logix Smart Coronavirus Disease 2019 (COVID-19) Kit",
  test_performed_code: "94534-5",
  test_ordered_code: "94534-5",
  test_result: "",
  order_test_date: "",
  specimen_collection_date: "",
  testing_lab_specimen_received_date: "",
  test_result_date: "",
  date_result_released: "",
  specimen_type: "258529004",
  ordering_provider_id: "1003876038",
  ordering_provider_last_name: "Dasghaib",
  ordering_provider_first_name: "Holly",
  ordering_provider_middle_name: "",
  ordering_provider_street: "620 W Covina Blvd",
  ordering_provider_street2: "",
  ordering_provider_city: "San Dimas",
  ordering_provider_state: "CA",
  ordering_provider_zip_code: "91773",
  ordering_provider_phone_number: "626-695-2426",
  testing_lab_clia: "05D2255279",
  testing_lab_name: "Biolab Mobile",
  testing_lab_street: "14738 Pipeline Ave E",
  testing_lab_street2: "",
  testing_lab_city: "Chino Hills",
  testing_lab_state: "CA",
  testing_lab_zip_code: "91709",
  testing_lab_phone_number: "909-407-4912",
  pregnant: "",
  employed_in_healthcare: "",
  symptomatic_for_disease: "",
  illness_onset_date: "",
  resident_congregate_setting: "",
  residence_type: "",
  hospitalized: "",
  icu: "",
  ordering_facility_name: "",
  ordering_facility_street: "",
  ordering_facility_street2: "",
  ordering_facility_city: "",
  ordering_facility_state: "",
  ordering_facility_zip_code: "",
  ordering_facility_phone_number: "",
  comment: "",
  test_result_status: "",
};

export const EXPORT_TO_CALREDIE_RESULT = {
  positive: "Positive",
  negative: "Negative",
  indeterminate: "Invalid Result",
  invalid: "Invalid Result",
};

export const SWAB_TYPES_OPT = [
  { label: "Oral", value: "oral", name: "swabTypes", id: "oralSwabTyp" },
  { label: "Nasal", value: "nasal", name: "swabTypes", id: "nasalSwabType" },
];
export const WORKER_OPT = [
  { label: "Yes", value: "yes", name: "worker", id: "yesWorker" },
  { label: "No", value: "no", name: "worker", id: "noWorker" },
];
export const POSSIBLE_EXPOSURE_OPT = [
  { label: "Yes", value: "yes", name: "possibleexposure", id: "yespossibleexposure" },
  { label: "No", value: "no", name: "possibleexposure", id: "nopossibleexposure" },
];
export const CONFIRMED_EXPOSURE_OPT = [
  { label: "Yes", value: "yes", name: "confirmedexposure", id: "yesconfirmedexposure" },
  { label: "No", value: "no", name: "confirmedexposure", id: "noconfirmedexposure" },
];
export const RADIO_OPTION_REF_INTERVAL = [
  { label: "Numeric", value: "ctscore", name: "refinterval", id: "CtScore" },
  { label: "Alpha", value: "alphabet", name: "refinterval", id: "Alphabet" },
];

export const ELIGIBILITY_STATUS_VALUE = {
  Eligibile: "Pass",
  eligibile: "Pass",
  Ineligibile: "Fail",
  ineligibile: "Fail",
  "Not Eligibile": "Fail",
};

export const ELIGIBILITY_STATUS_COLOR = {
  Pass: "green",
  pass: "green",
  Eligibile: "green",
  Fail: "maroon",
  fail: "maroon",
  "Not Eligibile": "maroon",
  "Not Checked": "yellow",
  "not checked": "yellow",
};

export const PANEL_RESULT_TYPE = [
  {
    value: "alpha",
    label: "Alpha",
    id: "alpha",
  },
];
export const SYMPTOMS_OPT = [
  {
    value: "FEVER / CHILLS",
    label: "FEVER / CHILLS",
    id: "fever",
  },
  {
    value: "COUGH",
    label: "COUGH",
    id: "cough",
  },
  {
    value: "HEADACHE",
    label: "HEADACHE",
    id: "headache",
  },
  {
    value: "FATIGUE",
    label: "FATIGUE",
    id: "fatigue",
  },
  {
    value: "SORE THROAT",
    label: "SORE THROAT",
    id: "soreThroat",
  },
  {
    value: "DIARRHEA",
    label: "DIARRHEA",
    id: "diarrhea",
  },
  {
    value: "SHORTNESS OF BREATH",
    label: "SHORTNESS OF BREATH",
    id: "shortnessOfBreath",
  },
  {
    value: "MUSCLE / BODY ACHES",
    label: "MUSCLE / BODY ACHES",
    id: "muscel",
  },
  {
    value: "NEW LOSS OF TASTE / SMELL",
    label: "NEW LOSS OF TASTE / SMELL",
    id: "newLossOfTaste",
  },
  {
    value: "CONGESTION / RUNNY NOSE",
    label: "CONGESTION / RUNNY NOSE",
    id: "congestion",
  },
  {
    value: "NAUSEA / VOMITING",
    label: "NAUSEA / VOMITING",
    id: "nausea",
  },
  {
    value: "NO SYMPTOMS / ASYMPTOMATIC",
    label: "NO SYMPTOMS / ASYMPTOMATIC",
    id: "noSymptoms",
  },
  {
    value: "OTHER",
    label: "OTHER",
    id: "other",
  },
];

export const UN_RESULT = "No Result";

export const TABLE_QUICK_TOOLS = {
  checkbox: "checkbox",
  edit: "edit",
  notes: "notes",
  delete: "delete",
  view: "view",
};

export const currencyformatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const EMP_INS_OBJECT = {
  insuranceCompany: "",
  insuranceCompanyCode: "",
  medicalNo: "",
  insuranceType: "",
  insuranceGroupId: "",
  medicareNo: "",
  deductible: 0,
  copay: 0,
  isActive: true,
  isNew: true,
};

export const COLUMNS = {
  1: "Primary",
  2: "Secondary",
  3: "Third",
  4: "Fourth",
  5: "Fifth",
  6: "Sixth",
  7: "Seventh",
  8: "Eighth",
  9: "Ninth",
  10: "Tenth",
  11: "Eleventh",
  12: "Twelfth",
};

export const MEDICATRE_IDS = [
  "00831",
  "02001",
  "10111",
  "10112",
  "26119",
  "ISA01",
  "07101",
  "07102",
  "03101",
  "03102",
  "BTHS1",
  "01911",
  "01111",
  "01102",
  "01192",
  "33070",
  "04111",
  "04102",
  "ISA02",
  "ISA03",
  "ISA04",
  "ISA05",
  "13102",
  "12201",
  "12202",
  "12301",
  "12101",
  "12102",
  "16003",
  "17003",
  "18003",
  "19003",
  "ISA06",
  "ISA07",
  "09101",
  "09102",
  "10211",
  "10212",
  "ISA08",
  "15004",
  "06014",
  "1100H",
  "01211",
  "01202",
  "05101",
  "05102",
  "02202",
  "06101",
  "00952",
  "08101",
  "08102",
  "04901",
  "ISA09",
  "05201",
  "05202",
  "ISA10",
  "15101",
  "15102",
  "07201",
  "07202",
  "ISA11",
  "ISA12",
  "14211",
  "14212",
  "12302",
  "14011",
  "14112",
  "ISA13",
  "05901",
  "12M65",
  "08201",
  "08202",
  "06201",
  "06202",
  "05301",
  "05302",
  "07301",
  "00512",
  "03201",
  "03001",
  "11501",
  "11502",
  "03302",
  "05401",
  "05402",
  "14312",
  "14013",
  "12401",
  "12402",
  "04211",
  "04202",
  "12M07",
  "12M82",
  "12M83",
  "12M45",
  "12M30",
  "01312",
  "01302",
  "13201",
  "13202",
  "13292",
  "13282",
  "15201",
  "15202",
  "04311",
  "04302",
  "02302",
  "12501",
  "12502",
  "09202",
  "ISA14",
  "ISA15",
  "00882",
  "14411",
  "14412",
  "ISA16",
  "45552",
  "11001",
  "11202",
  "03402",
  "ISA17",
  "ISA18",
  "ISA19",
  "MCAMA",
  "10311",
  "10312",
  "04411",
  "04402",
  "ISA20",
  "09302",
  "03501",
  "03502",
  "12004",
  "11302",
  "14512",
  "02402",
  "06001",
  "06302",
  "11402",
  "11003",
  "03602",
];

export const CUSTOM_INS = ["00001", "00002", "00003"];

export const IGNORE_INS = ["00000", "00001", "00002", "00003"];

export const CUSTOM_INS_ADV_PLAN = ["00001", "00002", "00003", "01192", "00882", "57016", "12302"];

export const PATIENT_BILL = ["00001", "00003"];

export const ON_HOLD_CPT = "11111";

export const CLAIM_SUBMIT_STATUS = {
  draft: "draft",
  submitted: "submitted",
  rejected: "rejected",
  approved: "approved",
  paid: "paid",
  denied: "denied",
  cancel: "cancel",
  partial: "partial",
  rejectedanddenied: "rejectedanddenied",
  unpaid: "unpaid",
};

export const DIAGNOSIS_ITEMS = ["A", "B", "C", "D", "E", "F", "G", "H"];

export const PARSE_ALPHA_INTO_NUM = {
  A: "1",
  B: "2",
  C: "3",
  D: "4",
  E: "5",
  F: "6",
  G: "7",
  H: "8",
};

export const PARSE_CLAIM_ERROR_KEY = {
  prov_npi: "Rendering NPI",
  ref_npi: "Referring NPI",
  ord_prov_npi: "Ordering NPI",
  ins_number: "Medicare policy number",
};

export const PREV_CLAIM_ACTIONS = {
  auto: "auto",
  update: "update",
  new: "new",
};
export const INSURANCE_TYPE = {
  primary: "primary",
  secondary: "secondary",
};

export const HOSPICE_MODFIRE = {
  GW: "GW",
  GV: "GV",
};

export const ProcTooltipHeader = ["Code", "Description"];

export const claimSubmitStatusColorOpts = {
  draft: "gray",
  submitted: "#3939ff",
  partial: "green",
  rejected: "red",
  approved: "green",
  sent: "green",
  paid: "green",
  denied: "#ff5f5f",
  cancelled: "#95614c",
  sent: "#3939ff",
  pending: "orange",
};

export const CONFIRMATION_TYPE = {
  DELETE: "delete",
  PROGRAM_START: "programstart",
  ASSIGN_PROGRAM: "assignprogram",
  UPDATE_TIME: "updateTime",
  AUTO_SHIPMENT: "autoshipment",
  PUBLIC_ORDER: "publicOrder",
  SUBMIT_CLAIM: "submitClaim",
  RESUBMIT_CLAIM: "reSubmitClaim",
  UPDATE_PATIENT: "updatePatient",
  CREATE_PATIENT: "createPatient",
};

export const PROCEDURE_CODE_STATES = {
  PLUS: "pluc",
  ADD: "add",
  UPDATE: "update",
};

export const PREV_CLAIM_TABLE = [
  "Date of Service",
  "Procedure Code",
  "Payer Name",
  "Amount Billed",
  "Rendering Provider",
  "Status",
];

export const PROCEDURE_CODE_HEADER = [
  "Date of Service",
  "Place of Service",
  "Procedure",
  "Modifier1",
  "Modifier2",
  "Modifier3",
  "Modifier4",
  "Diag1",
  "Diag2",
  "Diag3",
  "Diag4",
  "Billed",
  "Units",
];

export const RECEIVED_BYLAB = "Received By Lab";

export const NEW_PROVIDER_OBJECT = {
  name: "",
  clientID: CONFIG.clientID,
  firstName: "",
  middleName: "",
  lastName: "",
  code: "",
  npi: "",
  taxid: "",
  speciality: "",
  email: "",
  phoneNumber: "",
  street: "",
  street2: "",
  city: "",
  state: "",
  isActive: true,
};

export const NPI_TYPE_OPTIONS = [
  {
    label: "NPI-1",
    value: "NPI-1",
  },
  {
    label: "NPI-2",
    value: "NPI-2",
  },
];

export const NEW_DISCOVERY_ID = "ad1dd8c8-e32a-4f75-86e6-9be7c019af25";
export const BIO_LAB_ID = "456ba480-b8dd-47ea-a07a-940693f686ea";
export const ALTA_ID = "d1c2436f-f841-48b8-b834-9bce0471ea94";
export const ALTA_NEW_ID = "8183365e-32a5-4268-8e4e-87a292b9346a";
export const SEROCLINIX_ID = "e33ea32c-7cc2-4609-b3b2-35d8309df342";
export const SEROCLINIX_TEST = "9befb863-67fd-4d28-8f2d-7c5cbb1df522";
export const CALIBER_ID = "e6319b9f-3d5e-46ea-9c9b-0a3c84079ab5";
export const FUTURE_GENERATION_ID = "4bc78a52-e432-4bc0-8846-78e2d576dea3";

export const LAB_ID = {
  inc: "8cd1a74a-392c-4378-a8a0-15480ee37a1e",
  mobile: "9a41b680-4f5f-4914-a8f6-2229740dca0b",
  discovery: "3eaf991b-b876-4897-8e39-47aef2247dcd",
  alta: "6d8ec4ef-f634-4237-9984-c5b337e36546",
  seroclinix: "655f8008-f306-4052-a932-3f5622a39925",
  seroclinix_test: "a2172c3b-2997-4eee-9cc4-ff4cc38fd203",
  caliber: "ff763f06-3dd9-4101-bc52-34dc97c31385",
  future_generation: "4d519d64-f8db-4e6e-9396-fc723b70ae7e",
};

export const REQ_TEMPLATES = {
  ALTA_TOX: "altaNewToxFormPdf",
};

export const PHY_SIGN = "https://medflow-images.s3.eu-west-1.amazonaws.com/phy.png";

export const UPLOAD_CSV_FILE = {
  manualtest: "manualtest",
  test: "test",
  employee: "employee",
};

export const EOS_MODAL_GROUPS = [
  { value: "eos", label: "Scheduled", title: "Scheduled" },
  { value: "neos", label: "Not Scheduled", title: `Not Scheduled` },
];

export const ETHINICITY_OPTIONS = [
  { value: "Hispanic/Latino", label: "Hispanic/Latino" },
  { value: "Non Hispanic/Latino", label: "Non Hispanic/Latino" },
];

export const ANTIBIOTIC_RESISTANCE_GENES_TYPE = [
  { value: "Associated Test", label: "Associated Test" },
  { value: "Organism", label: "Bacteria/Organism" },
  { value: "Resistance", label: "Resistance Genes" },
  { value: "Additional Variable", label: "Additional Variable" },
];

export const ANTIBIOTIC_RESISTANCE_TYPE = {
  ORGANISM: "Organism",
  RESISTANCE: "Resistance",
  ASSOCIATED: "Associated Test",
  ADDITIONAL: "Additional Variable",
};

export const ELEMENT_TYPE = {
  "Bacteria/Organisms": "Organism",
  "Resistance Genes": "Resistance",
  "Associated Test": "Associated Test",
  "Additional Variable": "Additional Variable",
};

export const NOTIFICATION_STATUS = {
  pass: "pass",
  fail: "fail",
  pending: "pending",
  Parsed: "Parsed",
};

export const CRUD_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
};

export const ORDER_STATUS = {
  Pending: "yellow",
  Processing: "orange",
  Processed: "green",
  pass: "green",
  Cancel: "maroon",
  Cancelled: "maroon",
  Rejected: "maroon",
};


export const TEST_NO_COLOR = {
  Pending: "black",
  Processing: "orange",
  Processed: "green",
  pass: "green",
  Cancel: "maroon",
  Cancelled: "maroon",
  Rejected: "maroon",
};

export const TESTS_DONE_STATUS = {
  Pending: "yellow",
  Accepted: "green",
  sendOut: "green",
  Cancel: "maroon",
  Cancelled: "maroon",
  Rejected: "maroon",
};

export const ORDER_CANCELLED_RESASON = {
  "COVID positive": "COVID positive",
  Discharged: "Discharged",
  Refused: "Refused",
  "Out of Facility": "Out of Facility",
};

export const BCC_VALUE = {
  DEDUCTIBLE: "C",
  OUT_OF_POCKET: "G",
};

export const CPT_CODE_SEARCH_TYPE = {
  CPT: "cpt",
  ICT: "ict",
  TEST: "test",
};

export const ORDER_STATUS_OPT = [
  {
    value: "Pending",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="yellow" crossIcon /> Pending
      </div>
    ),
  },
  {
    value: "Processing",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="orange" crossIcon /> Processing
      </div>
    ),
  },
  {
    value: "Processed",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon /> Processed
      </div>
    ),
  },
  {
    value: "Rejected",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> Rejected
      </div>
    ),
  },
  {
    value: "Cancelled",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> Cancelled
      </div>
    ),
  },
];

export const EMPLOYEE_STATUS = [
  {
    value: "active",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon /> Active
      </div>
    ),
  },
  {
    value: "inactive",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> In Active
      </div>
    ),
  },
];

export const NETWORK_STATUS = [
  {
    value: "in network",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon /> In Network
      </div>
    ),
  },
  {
    value: "out of network",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> Out Of Network
      </div>
    ),
  },
];

export const OLD_ELIGIBILITY_STATUS = [
  {
    value: "Eligibile",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "Ineligibile",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
  {
    value: "Pending",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="yellow" questionIcon />
      </div>
    ),
  },
];

export const ELIGIBILITY_STATUS = [
  {
    value: "eligibile",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon /> Eligible
      </div>
    ),
  },

  {
    value: "invalid",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> In Eligible
      </div>
    ),
  },
];

export const ORDER_STATUS_DROPDOWN = [
  {
    value: "Pending",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="yellow" crossIcon /> Pending
      </div>
    ),
  },
  {
    value: "Processing",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="orange" crossIcon /> Processing
      </div>
    ),
  },
  {
    value: "Processed",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon /> Processed
      </div>
    ),
  },
  {
    value: "Cancelled",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> Cancelled
      </div>
    ),
  },
  {
    value: "Rejected",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> Rejected
      </div>
    ),
  },
];

export const BILLING_OPT = [
  {
    value: "stat",
    label: "STAT",
    id: "stat",
  },
  {
    value: "fax",
    label: "FAX",
    id: "cough",
  },
  {
    value: "call",
    label: "CALL",
    id: "call",
  },
  {
    value: "fasting",
    label: "FASTING",
    id: "fasting",
  },
  {
    value: "random",
    label: "RANDOM",
    id: "random",
  },
];

export const TEST_SETTING_TYPES = {
  TEST_LABEL: "testLabel",
  SOURCE: "source",
  DEPARTMENT: "department",
  UNIT: "unit",
  CANCEL_REASON: "cancel_reason",
  OPTIONS: "options",
  INSTRUMENT: "instrumentPlatform",
  SPECIMEN_CONTAINER : "specimenContainer",
  ROLE: "role",
};

export const SPECIMEN_CONTAINER_TYPE = [
  {
    value: "Containers",
    label: "Containers",
  },
  {
    value: "Dippers",
    label: "Dippers",
  },
  {
    value: "Jar",
    label: "Jar",
  },
  {
    value: "Vial",
    label: "Vial",
  },
  
]
export const DICTIONARY_OPTIONS = [
  {
    label: "Cancelled/Rejected Reason",
    value: "cancelled",
  },
  {
    label: "Department",
    value: "department",
  },
  {
    label: "Panel Element",
    value: "testPanels",
  },
  {
    label: "Source",
    value: "source",
  },

  {
    label: "Unit",
    value: "unit",
  },
  {
    label: "Associated Instrument",
    value: "instrumentPlatform",
  },
  {
    label : "Specimen Container",
    value : "specimenContainer"
  }
];

export const TEST_ASSAY_LOGS_SLUG = {
  TEST_CREATED: "Test Assay Created",
  TEST_UPDATED: "Test Assay Updated",
  TEST_ARCHIVED: "Test Assay Archived",
};

export const PERMISSIONS_LIST = [
  {
    label: "Client Base Role ",
    value: "clientBaseRole",
    disabled: true,
  },
  {
    label: "Patient ",
    value: "employee",
    subObject: [
      {
        label: "Create New Patients",
        value: "createPatient",
      },
      {
        label: "Archive Patients",
        value: "archivePatient",
      },
      {
        label: "Edit Patient Records",
        value: "editPatientRecord",
      },
      {
        label: "Add Patient Notes",
        value: "addPatientNote",
      },
    ],
  },
  {
    label: "Orders",
    value: "orders",
    subObject: [
      {
        label: "Add Orders",
        value: "addOrders",
      },
      {
        label: "Edit Orders",
        value: "editOrders",
      },
      {
        label: "Load Orders",
        value: "loadOrders",
      },
      {
        label: "Archive Orders",
        value: "archiveOrders",
      },
    ],
  },
  {
    label: "Tests",
    value: "test",
    subObject: [
      {
        label: "Create Tests",
        value: "createTest",
      },
      {
        label: "Edit Test Information",
        value: "editTest",
      },
      {
        label: "Result Tests",
        value: "resultTest",
      },
      {
        label: "Finalize/Send Results",
        value: "finalizeSendResult",
      },
      {
        label: "Archive Test",
        value: "archiveTest",
      },
    ],
  },
  { label: "Pending Results ", value: "pendingResults" },
  { label: "Completed Tests", value: "completedTests" },
  { label: "Check Eligibility", value: "checkEligibility", write: true, delete: true },
  { label: "Settings", value: "settings" },
  { label: "Provider Management", value: "providerManagement", write: true, delete: true },
  { label: "Client Management", value: "clientManagement", write: true, delete: true },
  { label: "Payer Management", value: "payerManagement", write: true },
  { label: "Diagnosis Codes", value: "diagnosisCodes", write: true },
  { label: "Procedure Codes", value: "procedureCodes", write: true },
  // { label: "Archived Tests", value: "archivedTests", access: true },
  // { label: "Archived Orders", value: "archivedOrders", access: true },
  {
    label: "System Users",
    value: "systemUsers",
    subObject: [
      {
        label: "Create New User",
        value: "createNewUser",
        checked: true,
      },
      {
        label: " Archive Users",
        value: "archiveUser",
        checked: true,
      },
      {
        label: "Edit User Credentials and Access",
        value: "editUserCredentials",
        checked: true,
      },
      {
        label: "Create New User Role",
        value: "createNewUserRole",
        checked: true,
      },
    ],
  },
  { label: "Tests Management", value: "testsManagement", write: true },
  { label: "Tests Dictionary", value: "testsDictionary", write: true, delete: true },
  { label: "Message Template", value: "messageTemplate", write: true, delete: true },
];

// export const DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS = {
//   employee: { access: true, write: true, delete: true },
//   orders: { access: true, write: true, delete: true },
//   pendingResults: { access: true, write: true, delete: true },
//   completedTests: { access: true, write: true, delete: true },
//   checkEligibility: { access: true, write: true, delete: true },
//   settings: { access: true },
//   providerManagement: { access: true, write: true, delete: true },
//   clientManagement: { access: true, write: true, delete: true },
//   payerManagement: { access: true, write: true },
//   diagnosisCodes: { access: true, write: true },
//   procedureCodes: { access: true, write: true },
//   systemUsers: { access: true, write: true, delete: true },
//   testsManagement: { access: true, write: true },
//   testsDictionary: { access: true, write: true, delete: true },
// };
export const DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS = {
  employee: {
    access: true,
    addPatientNote: true,
    archivePatient: true,
    createPatient: true,
    delete: true,
    editPatientRecord: true,
    write: true,
  },
  orders: {
    access: true,
    addOrders: true,
    archiveOrders: true,
    delete: true,
    editOrders: true,
    loadOrders: true,
    write: true,
  },
  pendingResults: { access: true },
  completedTests: { access: true },
  checkEligibility: { access: true, write: true, delete: true },
  settings: { access: true },
  providerManagement: { access: true, write: true, delete: true },
  clientManagement: { access: true, write: true, delete: true },
  payerManagement: { access: true, write: true, delete: true },
  diagnosisCodes: { access: true, write: true, delete: true },
  procedureCodes: { access: true, write: true, delete: true },
  archivedTests: { access: true },
  archivedOrders: { access: true },
  systemUsers: {
    access: true,
    archiveUser: true,
    createNewUser: true,
    createNewUserRole: true,
    delete: true,
    editUserCredentials: true,
    write: true,
  },
  test: {
    access: true,
    archiveTest: true,
    createTest: true,
    editTest: true,
    finalizeSendResult: true,
    resultTest: true,
  },
  testsManagement: { access: true, write: true, delete: true },
  testsDictionary: { access: true, write: true, delete: true },
  messageTemplate: { access: true, write: true, delete: true },
};

export const WHITE_LABEL_ROTUES = {
  "/order": "orders",
  "/patient": "employee",
  "/senttest": "pendingResults",
  "/alltests": "completedTests",
  "/checkeligibility": "checkEligibility",
  "/testtypes": "settings",
  "/archivedTests": "archivedTests",
  "/archivedOrders": "archivedOrders",
};
export const EMPLOYEE = "employee";
export const ORDER = "orders";
export const PENDING_RESULTS = "pendingResults";
export const COMPLETED_TESTS = "completedTests";
export const SETTINGS = "settings";
export const PROVIDER_MANAGEMENT = "providerManagement";
export const CLIENT_MANAGEMENT = "clientManagement";
export const PAYER_MANAGEMENT_VALUE = "payerManagement";
export const DIAGNOSIS_CODES = "diagnosisCodes";
export const PROCEDURE_CODES = "procedureCodes";
export const SYSTEM_USERS_VALUE = "systemUsers";
export const TESTS_MANAGEMENT = "testsManagement";
export const TESTS_DICTIONARY = "testsDictionary";
export const MESSAGE_TEMPLATE_VALUE = "messageTemplate";
export const REFERENCE_VALUE = "reference";
export const TAB_SETTINGS = "settings";

export const dependentPermissionsList = [
  "providerManagement",
  "clientManagement",
  "payerManagement",
  "diagnosisCodes",
  "procedureCodes",
  "systemUsers",
  "testsManagement",
  "testsDictionary",
  "messageTemplate",
];

export const TEST_PANEL_TYPES = {
  PANEL: "panel",
  QUALITATIVE_WITH_PATHOGEN: "qualitativeWithPathogen",
  QUALITATIVE_WITHOUT_PATHOGEN: "qualitativeWithOutPathogen",
  QUANTITATIVE_WITHOUT_PATHOGEN: "quantitativeWithOutPathogen",
  QUANTITATIVE_WITH_PATHOGEN: "quantitativeWithPathogen",
  BOTH: "both",
};

export const TYPE_OF_TEST = {
  QUANTITATIVE: "Quantitative",
  QUALITATIVE: "Qualitative",
  BOTH: "Both",
  EXTERNAL: "External Source",
};

export const CHECKELIGIBILITY = "checkEligibility";

export const ELIGIBILITY_HEADERS = ["firstName", "lastName", "dob", "medicareNo", "medicareAgent"];

export const PAYER_NETWORK_OPTIONS = [
  { value: "In Network", label: "In Network" },
  { value: "Out of Network", label: "Out of Network" },
];
export const DEFAULT_USER_PERSONALIZATION = {
  checkEligibility:
    '[{"id":1,"width":176},{"id":2,"width":144},{"id":3,"width":142},{"id":6,"width":113},{"id":7,"width":150},{"id":9,"width":142},{"id":10,"width":121},{"id":11,"width":108},{"id":12,"width":151},{"id":13,"width":148},{"id":14,"width":101},{"id":15,"width":160},{"id":16,"width":158}]',
  completedtest:
    '[{"id":1,"width":149},{"id":2,"width":147},{"id":3,"width":145},{"id":4,"width":106},{"id":5,"width":115},{"id":6,"width":121},{"id":7,"width":123},{"id":8,"width":110},{"id":9,"width":166},{"id":10,"width":121},{"id":11,"width":124},{"id":12,"width":106},{"id":13,"width":135},{"id":14,"width":126}]',

  employee:
    '[{"id":1,"width":160},{"id":2,"width":158},{"id":3,"width":326},{"id":4,"width":184},{"id":10,"width":175},{"id":11,"width":140},{"id":12,"width":160},{"id":13,"width":169},{"id":16,"width":155},{"id":17,"width":137}]',
  insurance:
    '[{"id":1,"width":71},{"id":2,"width":69},{"id":3,"width":90},{"id":4,"width":63},{"id":5,"width":71},{"id":6,"width":55},{"id":7,"width":127},{"id":8,"width":59},{"id":9,"width":80},{"id":10,"width":125},{"id":11,"width":121},{"id":12,"width":63},{"id":13,"width":117},{"id":14,"width":117},{"id":15,"width":103},{"id":16,"width":91},{"id":17,"width":92},{"id":18,"width":92},{"id":19,"width":75}]',
  labClient:
    '[{"id":1,"width":197},{"id":2,"width":275},{"id":13,"width":231},{"id":14,"width":273},{"id":15,"width":269},{"id":16,"width":211},{"id":17,"width":320}]',
  orderpersonalize:
    '[{"id":1,"width":186},{"id":2,"width":165},{"id":3,"width":163},{"id":4,"width":124},{"id":5,"width":145},{"id":6,"width":92},{"id":7,"width":135},{"id":8,"width":158},{"id":9,"width":174},{"id":10,"width":139},{"id":11,"width":182},{"id":12,"width":158}]',
  pendingtest:
    '[{"id":1,"width":132},{"id":2,"width":131},{"id":3,"width":129},{"id":4,"width":90},{"id":5,"width":99},{"id":6,"width":105},{"id":7,"width":94},{"id":8,"width":150},{"id":9,"width":165},{"id":10,"width":138},{"id":11,"width":141},{"id":12,"width":108},{"id":13,"width":110},{"id":14,"width":168}]',
  preregistration: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 24, 25, 26],
  provider:
    '[{"id":1,"width":208},{"id":2,"width":196},{"id":3,"width":156},{"id":4,"width":139},{"id":5,"width":178},{"id":6,"width":303},{"id":7,"width":94},{"id":8,"width":191},{"id":9,"width":165},{"id":10,"width":95},{"id":11,"width":213},{"id":12,"width":143},{"id":13,"width":259},{"id":14,"width":145},{"id":15,"width":153},{"id":16,"width":109},{"id":17,"width":144}]',
  diagnosis:
    '[{"id":0,"width":"100%"},{"id":1,"width":"100%"},{"id":2,"width":"100%"},{"id":2,"width":"100%"},{"id":2,"width":"100%"},{"id":4,"width":"100%"}]',
};

export const MESSAGE_TEMPLATE_CATGORY = [
  { value: "Patient Profile Created", label: "Patient Profile Created" },
  { value: "Order Placed", label: "Order Placed" },
  { value: "Order Rejected", label: "Order Rejected" },
  { value: "Test Performed", label: "Test Performed" },
  { value: "Test Resulted", label: "Test Resulted" },
];

export const archstone_STATUS_OPT = [
  {
    value: "Processed",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "Pending",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];

export const RELEASE_STATUS_OPTION = [
  {
    value: "Confirmed",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "Pending",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];
export const BATCH_OPTIONS_COMPLETED_TEST = [
  {
    value: "yes",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "no",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];

export const RESULT_EMAILED_OPTION = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const TYPE_TO_SHOW = {
  panel: "Panel",
  qualitativeWithPathogen: "Qualitative With Pathogen",
  qualitativeWithOutPathogen: "Qualitative Without Pathogen",
  quantitativeWithOutPathogen: "Quantitative Without Pathogen",
  quantitativeWithPathogen: "Quantitative With Pathogen",
};

export const OPTIONS_PLACEHOLDER = {
  Unit: "Enter Units",
  Department: "Enter Department Name",
};

export const rangeDataOptions = [
  {
    label: "Age Filter",
    value: "Age Filter",
    keyType: "age",
  },
  {
    label: "Diabetic",
    value: "Diabetic",
    keyType: "diabetic",
  },
  {
    label: "Female",
    value: "Female",
    keyType: "gender",
  },
  {
    label: "Fasting",
    value: "Fasting",
    keyType: "fasting",
  },
  {
    label: "Male",
    value: "Male",
    keyType: "gender",
  },
  {
    label: "Non Diabetic",
    value: "Non Diabetic",
    keyType: "diabetic",
  },
  {
    label: "Non Fasting",
    value: "Non Fasting",
    keyType: "fasting",
  },
  {
    label: "Not Specified",
    value: "Not Specified",
    keyType: "gender",
  },
  {
    label: "Not Binary",
    value: "Not Binary",
    keyType: "gender",
  },
];

export const PRINTER_SETTINGS_OPTIONS = [
  { value: "zebra", label: "Zebra" },
  { value: "dymo", label: "Dymo" },
];

export const PRINTER_LABEL_SIZE_OPTIONS = {
  dymo: [
    {
      label: "Small (1.2 in x 1.0 in)",
      value: "DymoSmall",
    },
    {
      label: "Medium (2.25 in x 1.25 in)",
      value: "DymoMedium",
    },
  ],
  zebra: [
    {
      label: "Medium (1.5 in x 1.0 in)",
      value: "ZebraMedium",
    },
    {
      label: "Large (2.1 in x 1.5 in)",
      value: "ZebraLarge",
    },
  ],
};

export const validationRules = {
  first: true,
  last: true,
  email: true,
  phone_number: true,
  dob: true,
  sex: true,
  insuranceCompanyCode: true,
  provider: true,
};

export const CSVValidationRules = {
  first_name: true,
  last_name: true,
  phone_number: true,
  dob: true,
  gender: true,
  referringProviderNpi: true,
  p_payer: true,
  p_member_id: true,
};

export const Sero_CSVValidationRules = {
  first_name: true,
  last_name: true,
  phone_number_email: true,
  dob: true,
  gender: true,
  address: true,
  city: true,
  state: true,
  zip: true,
};
